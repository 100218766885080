package com.macrofocus.common.interval

import com.macrofocus.common.collection.CollectionFactory.copyOnWriteArrayList
import com.macrofocus.common.collection.WeakReference

/**
 * This class provides a skeletal implementation of the MutableInterval interface to minimize the effort required to implement this interface.
 */
abstract class AbstractMutableInterval protected constructor() : MutableInterval {
    private val listeners: MutableList<IntervalListener>
    override fun addIntervalListener(listener: IntervalListener) {
        listeners.add(listener)
    }

    override fun addWeakIntervalListener(listener: IntervalListener) {
        val weakListener: WeakIntervalListener = WeakIntervalListener(listener)
        listeners.add(weakListener)
    }

    override fun removeIntervalListener(listener: IntervalListener) {
        if (listener is WeakIntervalListener) {
            val removed = listeners.remove(listener)
        } else {
            var toRemove: IntervalListener? = null
            for (intervalListener in listeners) {
                val comparable: IntervalListener?
                if (intervalListener is WeakIntervalListener) {
                    comparable = intervalListener.reference
                } else {
                    comparable = intervalListener
                }
                if (listener == comparable) {
                    toRemove = intervalListener
                }
            }
            if (toRemove != null) {
                val removed = listeners.remove(toRemove)
            }
        }
    }

    override fun removeIntervalListeners() {
        listeners.clear()
    }

    protected fun notifyIntervalChanged(event: IntervalEvent) {
        for (listener in listeners) {
            listener.intervalChanged(event)
        }
    }

    private inner class WeakIntervalListener(listener: IntervalListener) : IntervalListener {
        private val l_ref: WeakReference<IntervalListener>
        override fun intervalChanged(event: IntervalEvent) {
            val l = reference
            if (l != null) {
                l.intervalChanged(event)
            } else {
                removeIntervalListener(this)
            }
        }

        val reference: IntervalListener?
            get() = l_ref.get()

        override fun toString(): String {
            val l = reference
            return if (l != null) {
                "Weak[$l]"
            } else {
                this::class.toString() + "@" + hashCode()
            }
        }

        init {
            l_ref = WeakReference<IntervalListener>(listener)
        }
    }

    init {
        listeners = copyOnWriteArrayList<IntervalListener> ()
    }
}
