package ch.ethz.icr.growup

import kotlinx.serialization.Serializable

@Serializable
data class Box(
    /**
     * The lower left bottom corner of the box.
     */
    val llbX: Double,
    val llbY: Double,
    /**
     * The upper right top corner of the box.
     */
    val urtX: Double,
    val urtY: Double


) {
    override fun toString(): String {
        return "BOX($llbX $llbY, $urtX $urtY)"
    }
}