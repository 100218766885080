package ch.ethz.icr.growup.pfe.view

import ch.ethz.icr.growup.Country
import ch.ethz.icr.growup.TimePoint
import ch.ethz.icr.growup.pfe.theme.ThemeContext
import emotion.react.css
import js.objects.jso
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromDynamic
import org.jetbrains.letsPlot.Stat
import org.jetbrains.letsPlot.commons.values.Color
import org.jetbrains.letsPlot.facet.facetWrap
import org.jetbrains.letsPlot.font.fontFamilyInfo
import org.jetbrains.letsPlot.frontend.JsFrontendUtil
import org.jetbrains.letsPlot.geom.geomBar
import org.jetbrains.letsPlot.geom.geomPoint
import org.jetbrains.letsPlot.ggsize
import org.jetbrains.letsPlot.label.ggtitle
import org.jetbrains.letsPlot.letsPlot
import org.jetbrains.letsPlot.pos.positionStack
import org.jetbrains.letsPlot.scale.scaleColorGradient
import org.jetbrains.letsPlot.scale.scaleSize
import org.jetbrains.letsPlot.scale.scaleXDiscrete
import org.jetbrains.letsPlot.themes.elementBlank
import org.jetbrains.letsPlot.themes.elementText
import org.mkui.canvas.ResizeObserver
import org.w3c.fetch.RequestInit
import react.*
import react.dom.html.ReactHTML.div
import web.cssom.Position
import web.cssom.px
import web.dom.Node
import web.html.HTMLDivElement

fun resize(div: HTMLDivElement, setWidth: StateSetter<Int>, setHeight: StateSetter<Int>) {
    val width = div.clientWidth // Todo: offsetWidth
    val height = div.clientHeight // Todo: offsetHeight
    setWidth(width)
    setHeight(height)
}

external interface TimeSeriesProps : Props {
    var level: String
    var country: Country?
    var systemVariable: String
    var countryVariable: String
    var groupVariable: String
}

val TimeSeries = FC<TimeSeriesProps> { props ->
    var theme by useContext(ThemeContext)!!
//    val paletteType = if(theme.asDynamic().palette.mode == "dark") PaletteType.dark else PaletteType.light

    val (width, setWidth) = useState { 1600 }
    val (height, setHeight) = useState { 1200 }

    println("Creating resizable SVG")

//    val (country, _) = useAppState().country.toState()

    var histParentRef = useRef<HTMLDivElement>(null)

    useEffectOnceWithCleanup {
        val div = histParentRef.current!!
        val resizeObserver = ResizeObserver { _, _ -> resize(div, setWidth, setHeight) }
        resizeObserver.observe(div)

        onCleanup {
            resizeObserver.unobserve(div)
            resizeObserver.disconnect()
        }
    }

//    val type = "Area"
//    val (populationData, setPopulationData) = useState(null as JsonDataFrame?)
//    useEffect(type, country) {
//        if (country != null) {
//            GlobalScope.launch {
//                val dataFrame = window.fetch("/countryyear/onset_do_flag").then {
//                    //                async { JsonDataFrame(it.json().await().unsafeCast<Array<Any?>>()) }
//                    async { JsonDataFrame(it.text().await()) }
//                }.await().await()
//                println("Setting data frame $dataFrame")
//                setPopulationData(dataFrame)
//            }
//        } else {
//            setPopulationData(null)
//        }
//    }
    var timePoints by useState<List<TimePoint>>()

    useMemo(props.level, props.country, props.countryVariable) {
        GlobalScope.launch {
            println("Querying ${props.level}, ${props.country}, ${props.countryVariable}")
            timePoints = getTimePoints(props.level, props.country, props.countryVariable)
        }
    }


    useEffect(timePoints, width, height) {
        if (timePoints != null) {
            val n = timePoints!!.size
            println("Got $n")
            val data = mapOf<String, Any>(
                "year" to List(n) { timePoints!![it].year },
                "value" to List(n) { timePoints!![it].value }
            )

            val p = letsPlot(data) +
                    org.jetbrains.letsPlot.themes.theme(
                        axisTitleX = elementBlank(),
                        text = elementText(family = "Roboto"),
                        axisTextX = elementText(family = "Roboto"),
                        axisTextY = elementText(family = "Roboto"),
                        panelBackground = elementBlank(),
                        plotBackground = elementBlank(),
                    ) +
                    geomBar(
                        position = positionStack(),
                        stat = Stat.identity,
                        alpha = 0.75,
                        fill = Color(0, 0, 128),
                        color = Color(128, 128, 128),
                        size = 0

                    ) {
                        x = "year"
                        y = "value"
                    } +
//                    scaleXContinuous(limits = Pair(1946, 2022)) +
                    scaleXDiscrete(limits = (1946..2022).toList(), breaks = listOf(1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020)) +
                    fontFamilyInfo("Roboto", mono = false) +
//                    (if(paletteType == PaletteType.dark) flavorSolarizedDark() else flavorHighContrastLight())  +
                    ggsize(width, height)

            val dates = listOf(
                "2024-01-01", "2024-02-01", "2024-03-01",
                "2024-04-01", "2024-05-01", "2024-06-01"
            )
            val timeSeriesData = listOf(
                mapOf("Date" to dates, "Value" to listOf(10, 15, 20, 18, 25, 30), "Size" to listOf(5, 7, 9, 6, 8, 10)),
                mapOf("Date" to dates, "Value" to listOf(8, 12, 18, 16, 22, 28), "Size" to listOf(6, 8, 10, 7, 9, 11)),
                mapOf("Date" to dates, "Value" to listOf(12, 18, 25, 22, 30, 35), "Size" to listOf(7, 9, 11, 8, 10, 12))
            )

            // Create plots for each time series with row labels
            val plots = timeSeriesData.mapIndexed { index, series ->
                val p = letsPlot(series) { x = "Date"; y = "Value"; color = "Value"; size = "Size" } +
                        geomPoint() +
                        scaleColorGradient(low = "blue", high = "red") +
                        scaleSize(range = Pair(3, 10)) +
//                        org.jetbrains.letsPlot.themes.theme().axisTitleY_blank() +
                        ggtitle("Row ${index + 1}")
                p
            }
//
//            // Arrange plots in a grid layout
            val layout = facetWrap(plots, ncol = 1)

//            val plot = letsPlot() +
//                    geomPoint(timeSeriesData[0]) { x = "Date"; y = "Value"; color = "Value"; size = "Size" } +
//                    scaleColorGradient(low = "blue", high = "red") +
//                    scaleSize(range = Pair(3, 10)) +
////                    theme().axisTitleY_blank() +
//                    facetWrap(timeSeriesData, ncol = 1)

            val plotDiv: org.w3c.dom.HTMLDivElement = JsFrontendUtil.createPlotDiv(p)

            val current: HTMLDivElement = histParentRef.current!!
            var child = current.lastElementChild;
            while (child != null) {
                current.removeChild(child)
                child = current.lastElementChild
            }
            //        current.remove()
            current.appendChild(plotDiv as Node)
        } else {
            val current: HTMLDivElement = histParentRef.current!!
            var child = current.lastElementChild;
            while (child != null) {
                current.removeChild(child)
                child = current.lastElementChild
            }
        }
    }

    div {
        css {
            position = Position.absolute
            left = 0.px
            right = 0.px
            top = 0.px
            bottom = 0.px
        }

        ref = histParentRef
    }
}

internal operator fun Double?.minus(other: Double?) = if (this != null && other != null) this - other else this

suspend fun getTimePoints(level: String, country: Country?, variable: String): List<TimePoint> {
    val url = if(country != null && level != "system") "/countryyear/${country.gwid}/$variable" else "/countryyear/$variable"
    val response = window.fetch(url, RequestInit(headers = jso {
        Accept = "application/json"
    }))
        .await()
        .json()
        .await()
    return Json.decodeFromDynamic(response)
}