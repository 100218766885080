package com.macrofocus.common.interval

/**
 * / ** An event that characterizes a change in the current interval.
 */
class IntervalEvent(val oldStart: Double, val oldEnd: Double, val oldExtent: Double) {
    var newStart = 0.0
        private set
    var newEnd = 0.0
        private set
    var newExtent = 0.0
        private set

    fun setNewValues(newStart: Double, newEnd: Double, newExtent: Double) {
        this.newStart = newStart
        this.newEnd = newEnd
        this.newExtent = newExtent
    }

    override fun toString(): String {
        return "IntervalEvent{" +
                "newStart=" + newStart +
                ", newEnd=" + newEnd +
                ", newExtent=" + newExtent +
                ", oldStart=" + oldStart +
                ", oldEnd=" + oldEnd +
                ", oldExtent=" + oldExtent +
                '}'
    }
}
