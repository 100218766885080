package ch.ethz.icr.growup.pfe.context

import ch.ethz.icr.growup.Country
import ch.ethz.icr.growup.pfe.page.calculateZoomLevel
import com.macrofocus.common.properties.MutableProperty
import com.macrofocus.common.properties.SimpleProperty
import js.objects.jso
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromDynamic
import org.w3c.fetch.RequestInit
import react.router.NavigateFunction
import react.router.useMatch
import react.router.useParams
import react.useEffect

class AppState {
    val viewId: MutableProperty<String> = SimpleProperty("")
    val year: MutableProperty<Int> = SimpleProperty(2023)
    val countryId: MutableProperty<String> = SimpleProperty("")
    val country: MutableProperty<Country?> = SimpleProperty(null)
    val groupId: MutableProperty<String> = SimpleProperty("")

    val width: MutableProperty<Int> = SimpleProperty(500)
    val height: MutableProperty<Int> = SimpleProperty(500)
    val zoom: MutableProperty<Double> = SimpleProperty(1.1)
    val longitude: MutableProperty<Double> = SimpleProperty(0.0)
    val latitude: MutableProperty<Double> = SimpleProperty( 47.021278030856564)
//    val longitude: MutableProperty<Double> = SimpleProperty(7.969664962869274)
    val pitch: MutableProperty<Double> = SimpleProperty(0.0)
    val bearing: MutableProperty<Double> = SimpleProperty(0.0)

    val countries: MutableProperty<List<Country>?> = SimpleProperty(null)

    val level: MutableProperty<String> = SimpleProperty("country")
    val systemVariable: MutableProperty<String> = SimpleProperty("incidence_flag")
    val countryVariable: MutableProperty<String> = SimpleProperty("exclpop")
    val groupVariable: MutableProperty<String> = SimpleProperty("statusid")

    init {
        GlobalScope.launch {
            countries.value = getCountries()
        }
    }

    inline fun applyParams() {
        var countryId: String? = null
        val countryId1 = useMatch("/pfe/:countryId")?.params?.get("countryId")
        val countryId2 = useMatch("/atlas/:countryId")?.params?.get("countryId")
        if(countryId1 != null) {
            countryId = countryId1
        } else {
            countryId = countryId2
        }
        if(countryId != null) {
            println("Restoring $countryId from params")

            this.countryId.value = countryId
            val countries = countries.value

            if(countryId.isNotBlank()) {
                val country = countries?.find { it.countryname == countryId }
                if (country != null) {
                    this.country.value = country
                    println("Zooming on country $country")
                    zoomOnCountry(country)
                } else {
                    println("Country list not yet received")
                }
            }

        }
    }

    inline fun navigateToCountry(navigate: NavigateFunction, countryId: String){
//        var viewId = useViewIdParam()
//        if(viewId == "") {
//            viewId = "pfe"
//        }
        val viewId = "pfe"

        navigate("/$viewId/$countryId")

//        useEffect(countries, countryId) {
        println("Request zooming on $countryId")

        val countries = countries.value
        if(countries != null) {

            val country = countries.find { it.countryname == countryId }
            if (country != null) {
                println("Zooming on country $country")
                zoomOnCountry(country)
            }
        }
    }

    suspend fun getCountries(): List<Country> {
        val response = window.fetch("/countries", RequestInit(headers = jso {
            Accept = "application/json"
        }))
            .await()
            .json()
            .await()

        val countries = Json.decodeFromDynamic<List<Country>>(response)
//        val countryId = countryId.value
//
//        if(countryId.isNotBlank()) {
//            val country = countries.find { it.countryname == countryId }
//            if (country != null) {
//                println("Zooming on country $country")
//                zoomOnCountry(country)
//            }
//        }

        return countries
    }

    fun zoomOnCountry(country: Country) {
        val bbox = country.maxExtent
        if(bbox != null) {
            val minLon = bbox.urtX
            val minLat = bbox.urtY
            val maxLon = bbox.llbX
            val maxLat = bbox.llbY

            // Calculate the center of the bounding box
            val centerLon = (minLon + maxLon) / 2.0
            val centerLat = (minLat + maxLat) / 2.0
            val zoom = calculateZoomLevel(bbox, width.value, height.value)
            println("Zooming on $bbox -> $centerLon,$centerLat,$zoom")
            this.longitude.value = centerLon
            this.latitude.value = centerLat
            this.zoom.value = zoom
            val INITIAL_VIEW_STATE: dynamic = jso {
                this.latitude = centerLat
                this.longitude = centerLon
                this.zoom = zoom // 6
                this.pitch = 0
                this.bearing = 0

                transitionDuration = 1000
//                                transitionInterpolator = FlyToInterpolator()

                maxZoom = 16
            }
        }
    }
}