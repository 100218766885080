package ch.ethz.icr.growup.pfe.context

import react.RequiredContext
import react.createRequiredContext
import react.useRequiredContext

val AppStateContext: RequiredContext<AppState> =
    createRequiredContext()

fun useAppState(): AppState =
    useRequiredContext(AppStateContext)
