package ch.ethz.icr.growup.pfe.view

import ch.ethz.icr.growup.pfe.context.useAppState
import emotion.react.css
import mui.material.Card
import mui.material.CardContent
import mui.system.sx
import org.mkui.react.toState
import react.FC
import react.Props
import react.dom.html.ReactHTML.iframe
import web.cssom.Position
import web.cssom.pct
import web.cssom.vh

val AtlasView = FC<Props> {
    val (country, _) = useAppState().countryId.toState()

    Card {
        CardContent {
            sx {
                position = Position.relative
//                width = 100.vw
                height = 100.vh
            }

            iframe {
                css {
                    width = 100.pct
                    height = 100.pct
                }

                src = "https://growup.ethz.ch/atlas/pdf/$country.pdf#page=2"
            }
        }
    }
}
