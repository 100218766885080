package com.macrofocus.common.collection

class UniversalComparator : Comparator<Any?> {
    override fun compare(a: Any?, b: Any?): Int {
        return if (a != null && b != null) {
            if (b::class == a::class) {
                if (a is String && b is String) {
                    a.compareTo(b)
                } else if (a is Comparable<*> && b is Comparable<*>) {
                    (a as Comparable<Any?>).compareTo(b)
                } else {
                    a.toString().compareTo(b.toString())
                }
            } else if (b is Number && a is Number) {
                val number1 = a
                val number2 = b
                if (number1.toDouble() < number2.toDouble()) return -1
                if (number1.toDouble() > number2.toDouble()) 1 else 0
            } else {
                a.toString().compareTo(b.toString())
            }
        } else {
            if (a != null) {
                1
            } else if (b != null) {
                -1
            } else {
                0
            }
        }
    }

    fun compare(number1: Number, number2: Number): Int {
        if (number2::class == number1::class) {
            // both numbers are instances of the same type!
            if (number1 is Comparable<*>) {
                // and they implement the Comparable interface
                return (number1 as Comparable<*>).compareTo(number2 as Nothing)
            }
        }
        // for all different Number types, let's check there double values
        if (number1.toDouble() < number2.toDouble()) return -1
        return if (number1.toDouble() > number2.toDouble()) 1 else 0
    }
}
