package ch.ethz.icr.growup.pfe.page

import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.router.Outlet
import web.cssom.Auto
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.px

val Content = FC<Props> {
    Box {
        sx {
            // To ensure that the absolutely positioned child element is contained within the grid container, you should set position: relative (or any non-static position) on the grid container.
            // This way, the child element will be positioned relative to the grid container, not the entire screen.
            position = Position.relative

            gridArea = Area.Content
            padding = 0.px
            overflowY = Overflow.clip
        }

        component = ReactHTML.main

        Outlet()
    }
}