package org.mkui.font.crossplatform

import org.mkui.font.Posture
import org.mkui.font.Weight

actual class CPFont(
    private val family: String,
    private val size: Double,
    val weight: Weight? = Weight.NORMAL,
    val posture: Posture? = Posture.REGULAR
) {
    private val name: String by lazy {
        val html = StringBuilder()
        when {
            weight === Weight.BOLD -> {
                html.append("bold ")
            }
            posture === Posture.ITALIC -> {
                html.append("italic ")
            }
            else -> {
                html.append("normal ")
            }
        }
        html.append((size * 72 / 96).toString() + "pt ")
        if (family != null) {
            html.append(family)
        }
        html.toString()
    }

    val nativeFont: String
        get() {
            return toHtml()
        }

    actual fun getSize(): Double {
        return size
    }

    actual fun deriveFontSize(fontSize: Double): CPFont {
        return CPFont(family, fontSize, weight, posture)
    }

    actual fun getSize2D(): Double {
        return size
    }

    actual fun deriveFontWeight(weight: Weight): CPFont {
        return CPFont(family, size, weight, posture)
    }

    fun toHtml(): String {
        return name
    }

    override fun toString(): String {
        return name
    }
}