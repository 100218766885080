package org.mkui.color

actual class MkColorFactory {
    actual companion object {
        actual val instance = MkColorFactory()
    }

    actual val white by lazy { colorOf(255u, 255u, 255u) }
    actual val lightGray by lazy { colorOf(192u, 192u, 192u) }
    actual val gray by lazy { colorOf(128u, 128u, 128u) }
    actual val darkGray by lazy { colorOf(64u,64u,64u) }
    actual val black by lazy { colorOf(0u, 0u, 0u) }
}