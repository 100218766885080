package ch.ethz.icr.growup.pfe.theme

import react.FC
import react.PropsWithChildren
import react.useState

val YearModule = FC<PropsWithChildren> { props ->
    val state = useState(2000)
    val (year) = state

    YearContext(state) {
        +props.children
    }
}