package ch.ethz.icr.growup.pfe.view

import mui.material.*
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.div

val LandingView = FC<Props> {
    Card {
        CardHeader {
            title = ReactNode("The aim of the GROWᵁᴾ federated data platform is to provide management and access to disaggregated, integrated, spatially explicit, and user-friendly conflict-related data.")
        }
        CardContent {
            Typography {
                component = div
//                variant = h5

                +"The aim of the GROWᵁᴾ federated data platform is to provide management and access to disaggregated, integrated, spatially explicit, and user-friendly conflict-related data."
            }
            Typography {
                component = div
//                variant = h5

                +"Under View, the GROWᵁᴾ Public Front End offers a visualization of settlement patterns of politically active ethnic groups around the world from 1946-2020. Additionally, it provides information about ethnic groups' access to executive government power, their involvement in civil war, federal administrative units, physical elevation, nightlight data, as well as population and GDP data by area."
            }

            Typography {
                component = div
//                variant = h5

                +"Under Read, the content of the EPR Atlas is provided in PDF format, allow easy access to easily readable format."
            }

            Typography {
                component = div
//                variant = h5

                +"Under Download, the GROWᵁᴾ Research Front End offers research-ready data on ethnic groups and intrastate conflict. Our data is compiled from various sources and provided in group-year and country-year format... ready for statistical analysis!"
            }

            Typography {
                component = div
//                variant = h5

                +"Under Code, the GROWᵁᴾ Coding Front End offers an effective mean of collaboratively collecting geo-referenced data."
            }
        }
        CardActions {
            Button {
                size = Size.small
                +"Learn More"
            }
        }
    }
}
