package org.molap.index


class IntegerRangeUniqueIndex(private val from: Int, private val to: Int) : AbstractUniqueIndex<Int>() {
    override fun getAddress(key: Int): Int {
        return if (contains(key)) key - from else -1
    }

    override fun head(count: Int): UniqueIndex<Int> {
        return IntegerRangeUniqueIndex(from, from + count)
    }

    override fun tail(count: Int): UniqueIndex<Int> {
        return IntegerRangeUniqueIndex(to - count, to)
    }

    override operator fun contains(key: Int): Boolean {
        return key >= from && key <= to
    }

    override fun getKey(i: Int): Int {
        return from + i
    }

    override fun keys(): Iterable<Int> {
        return object : Iterable<Int> {
            override fun iterator(): Iterator<Int> {
                return object : MutableIterator<Int> {
                    var i = from
                    override fun hasNext(): Boolean {
                        return i <= to
                    }

                    override fun next(): Int {
                        return i++
                    }

                    override fun remove() {
                        throw UnsupportedOperationException()
                    }
                }
            }
        }
    }

    override val size: Int
        get() = to - from + 1

    override fun keepAddresses(addresses: IntArray): UniqueIndex<Int> {
        val keys = IntArray(addresses.size)
        for (i in addresses.indices) {
            keys[i] = from + addresses[i]
        }
        return IntegerListUniqueIndex(keys)
    }

    override fun toString(): String {
        return "IntegerRangeUniqueIndex{" +
                "from=" + from +
                ", to=" + to +
                '}'
    }

}
