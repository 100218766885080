package com.macrofocus.common.collection

actual object CollectionFactory {
    actual fun <T> copyOnWriteArrayList(): MutableList<T> {
        return ArrayList()
    }

    actual fun <K, V> concurrentHashMap(): MutableMap<K, V> {
        return HashMap()
    }

    actual fun <T> linkedHashSet(): MutableSet<T> {
        return LinkedHashSet()
    }

    actual fun <T> concurrentLinkedHashSet(): MutableSet<T> {
        return LinkedHashSet()
    }
}