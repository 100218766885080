package ch.ethz.icr.growup.pfe

import csstype.sx
import react.create
import react.dom.client.createRoot
import web.cssom.vh
import web.dom.document
import web.html.HTML.div

fun main() {
    val root = document.createElement(div)
        .apply { sx { height = 100.vh } }
        .also { document.body.appendChild(it) }

    val pfe = PFE.create {
    }

    createRoot(root).render(pfe)
}