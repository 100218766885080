package org.molap.series

import org.molap.index.IntegerRangeUniqueIndex
import org.molap.index.UniqueIndex
import kotlin.reflect.KClass

class DefaultSeries<V> : AbstractSeries<Int, V>, MutableSeries<Int, V> {
    override val name: Any?
    private val values: Array<V>
    private val index: UniqueIndex<Int>
    override val type: KClass<out Any>

    constructor(values: Array<V>, name: Any? = null, type: KClass<out Any> = Any::class, index: UniqueIndex<Int> = IntegerRangeUniqueIndex(0, values.size - 1)) {
        this.values = values
        this.type = type
        this.name = name
        this.index = index
    }

    //    override val type: KClass<out Any>
//        get() = values.component1()!!::class

    override fun get(key: Int): V {
        return values[key]
    }

    override fun getKey(i: Int): Int {
        return index.getKey(i)
    }

    override fun size(): Int {
        return index.size
    }

    override fun getAddress(key: Int): Int {
        return index.getAddress(key)
    }

    override operator fun set(key: Int, value: V) {
        values[key] = value
    }

    override fun keys(): Iterable<Int> {
        return index.keys()
    }

//    fun <L> reindex(vararg keys: L?): Series<L?, V?>? {
//        return null
//    }

    fun head(count: Int): Series<Int, V> {
        return reindex(index.head(count))
    }

    fun tail(count: Int): Series<Int, V> {
        return reindex(index.tail(count))
    }

    fun <K> reindex(index: UniqueIndex<K>): Series<K, V> {
        return IndexedSeries<K, V>(name, values, index)
    }
}
