package ch.ethz.icr.growup.pfe.page

import ch.ethz.growup.server.BuildKonfig
import ch.ethz.icr.growup.pfe.context.useAppState
import ch.ethz.icr.growup.pfe.router.useViewIdParam
import ch.ethz.icr.growup.pfe.theme.*
import ch.ethz.icr.growup.pfe.view.Up
import ch.ethz.icr.growup.pfe.view.View
import js.objects.jso
import js.uri.encodeURIComponent
import kotlinx.browser.window
import mui.icons.material.*
import mui.material.*
import mui.material.Size
import mui.material.Tab
import mui.material.styles.TypographyVariant
import mui.system.sx
import org.mkui.react.toState
import react.FC
import react.ReactNode
import react.create
import react.dom.aria.AriaHasPopup.Companion.`false`
import react.dom.aria.ariaHasPopup
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML
import react.router.useLoaderData
import react.router.useNavigate
import web.cssom.*
import web.events.Event

val Header = FC {
    val theme = useTheme()
    val setTheme = useSetTheme()
//    val (viewId, _) = useAppState().viewId.toState()
    val viewId = useViewIdParam()

    val views = useLoaderData().unsafeCast<Array<out View>>()

    AppBar {
        sx {
            gridArea = Area.Header
            zIndex = integer(1_500)
        }

        position = AppBarPosition.absolute

        Toolbar {
            Box {
                sx {
                    width = Sizes.Sidebar.Width
                    display = Display.inlineFlex
                    justifyContent = JustifyContent.center
                }

                Logo {
//                sx { width = Sizes.Sidebar.Width }

                    version = BuildKonfig.growupVersion
                    logo = if (theme.palette.mode == PaletteMode.light) "/eth_logo_kurz_pos.svg" else "/eth_logo_kurz_neg.svg"
                    height = 54.0
                    marginLeft = -12.px
                }
            }

            Tooltip {
                title = ReactNode("Geographical Research On War, Unified Platform")
                Typography {
                    sx {
                        flexGrow = number(1.0)
                        fontStyle = web.cssom.FontStyle.italic
                    }

                    component = ReactHTML.div
                    variant = TypographyVariant.h6
                    noWrap = true

                    +"GROW"
                    Up()
//                    +" - Geographical Research On War, Unified Platform"
                }
            }

            val navigate = useNavigate();

            Tabs {
                val views = useLoaderData().unsafeCast<Array<out View>>()
                val (countryId, _) = useAppState().countryId.toState()

//                sx {
//                    gridArea = Area.Footer
//                }

//                component = ReactHTML.footer

//                showLabels = true
//                value = decodeURIComponent(viewId)
                value = viewId

                onChange = { _, key ->
                    val destination: String
                    val uri = encodeURIComponent(key as String)
                    // We keep the country when we switch from PFE view and Atlas read
                    if((uri == "pfe" || uri == "atlas") && countryId.isNotBlank()) {
                        destination = "$uri/$countryId"
                    } else if(uri == "atlas" && countryId.isBlank()) {
                        destination = "$uri/Afghanistan"
                    } else {
                        destination = uri
                    }
                    println("Navigating to $destination")
                    navigate(destination)
                }

                for ((key, name, _, icon) in views.filter { it.icon != null }) {
                    Tab {
                        label = ReactNode(name)
                        this.icon = icon
//                        value = decodeURIComponent(key)
                        value = key as String
                    }
                }
            }

            Tooltip {
                title = ReactNode("Filler")
                Typography {
                    sx {
                        flexGrow = number(1.0)
                    }

                    component = ReactHTML.div
                    variant = TypographyVariant.h6
                    noWrap = true

                    +""
                }
            }

//            Tooltip {
//                title = ReactNode("Year")
//
//                val year = useYear()
//                val setYear = useSetYear()
//
//                Slider {
//                    sx = jso {
//                        width = 10.vw
//                        marginRight = 10.px
//                    }
//
//                    value = year
//
//                    step = 1
//                    marks
//                    min = 1946
//                    max = 2023
//                    size = Size.small
//                    valueLabelDisplay = "auto"
//
//                    onChange = { event: Event, value: dynamic, activeThumb: Number ->
//                        println("onChange: $value")
//                        if (value is Number) {
//                            setYear(value.toInt())
//                        }
//                    }
//                    onChangeCommitted = { event: react.dom.events.SyntheticEvent<*, *>, value: dynamic ->
//                        println("onChangeCommitted: $value")
//                        if (value is Number) {
//                            setYear(value.toInt())
//                        }
//                    }
//                }
//            }


            Tooltip {
                title = ReactNode("Theme")

                Switch {
                    ariaLabel = "theme"

                    icon = Brightness7.create()
                    checkedIcon = Brightness4.create()
                    checked = theme == Themes.Dark

                    onChange = { _, checked ->
                        setTheme(if (checked) Themes.Dark else Themes.Light)
                    }
                }
            }

//            Tooltip {
//                title = ReactNode("Read Documentation")
//
//                IconButton {
//                    ariaLabel = "official documentation"
//                    ariaHasPopup = `false`
//
//                    size = Size.large
//                    color = IconButtonColor.inherit
//                    onClick = {
//                        window.location.href = "https://icr.ethz.ch/"
//                    }
//
//                    MenuBook()
//                }
//            }

            Tooltip {
                title = ReactNode("View Sources")

                IconButton {
                    ariaLabel = "source code"
                    ariaHasPopup = `false`

                    size = Size.large
                    color = IconButtonColor.inherit
                    onClick = {
//                        var name = viewId
//                            .split("-")
//                            .asSequence()
//                            .map { it.replaceFirstChar(Char::titlecase) }
//                            .reduce { accumulator, word -> accumulator.plus(word) }
//
//                        if (name.isNotEmpty()) {
//                            name += ".kt"
//                        }

                        // TODO: Remove it after storing selected showcase
//                        name = ""

                        window.location.href =
                            "https://icr.ethz.ch/data/"

//                        navigate("https://icr.ethz.ch/data/")
                    }

                    Source()
                }
            }
        }
    }
}