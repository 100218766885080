package org.molap.dataframe

import org.molap.index.DefaultUniqueIndex
import org.molap.index.IntegerRangeUniqueIndex
import org.molap.index.UniqueIndex
import kotlin.js.Json
import kotlin.reflect.KClass

@JsExport
class JsonDataFrame(private val array: Array<Any?>) : AbstractDataFrame<Int, String, Any?>() {
    private val labels: Array<String>
    private val classes: Array<KClass<*>?>
    init {
//        this.array = array
        val columns = LinkedHashSet<String>()
        val types: HashMap<String, KClass<*>> = HashMap<String, KClass<*>>()
        for (row in 0 until array.size) {
            val o: Json = array.get(row).unsafeCast<Json>()
            for (column in js("Object").keys(o)) {
                if (!columns.contains(column)) {
                    columns.add(column)
                    val value: Any? = o.get(column)
                    val type: KClass<*>? = getClass(value)
                    if (type != null) {
                        types[column] = type
                    }
                } else {
                    val value = o.get(column)
                    val type: KClass<*>? = getClass(value)
                    if (type != null) {
                        val currentType: KClass<*>? = types[column]
                        if (currentType == null) {
                            types[column] = type
                        } else if (currentType != type) {
                            types[column] = Any::class
                        }
                    }
                }
            }
        }
        labels = columns.toTypedArray()
        classes = arrayOfNulls<KClass<*>>(columns.size)
        var index = 0
        for (column in columns) {
            val type: KClass<*>? = types[column]
            classes[index] = if (type != null) type else Any::class
            index++
        }

//        for (String column : columns) {
//            getLogger().log(Level.INFO, column + ": " + getColumnClass(column));
//        }
    }

//    private val array: Array<Any?>
    override val rowIndex: UniqueIndex<Int> by lazy { IntegerRangeUniqueIndex(0, array.size - 1) }
    override val columnIndex: UniqueIndex<String> by lazy {
        DefaultUniqueIndex<String>(*labels.copyOf())
    }

    @JsName("fromString")
    constructor(json: String) : this(JSON.parse<Json>(json)["data"].unsafeCast<Array<Any?>>()) {}

    override fun getColumnName(column: String): String? {
        return labels[columnIndex.getAddress(column)]
    }

    override fun getColumnClass(column: String): KClass<*> {
        val address = columnIndex.getAddress(column)
        return classes[address]!!
    }

    override val rowCount: Int
        get() = array.size

    override val columnCount: Int
        get() = labels.size

    override fun getRowClass(row: Int): KClass<*>? {
        return Any::class
    }

    override fun getValueAt(row: Int, column: String): Any? {
        val jsonObject = array.get(row)
        if(jsonObject != null) {
            val jsonValue: Any? = (jsonObject as Json).get(column!!)

            /*
             * This is subject to the bug where number 0 is treated as null! See
             * https://github.com/gwtproject/gwt/issues/9484
             */return if (jsonValue == null) {
                null
            } else {
                if (getColumnClass(column) == Double::class) {
                    //                jsonObject.getNumber(column)
                    jsonValue as Number
                } else if (getColumnClass(column) == Boolean::class) {
                    //                jsonObject.getBoolean(column)
                    jsonValue as Boolean
                } else {
                    try {
                        getObject(jsonValue)
                    } catch (e: ClassCastException) {
                        // GWT may throw this exception, probably if the column doesn't exist
                        e.printStackTrace()
                        null
                    }
                }
            }
        } else {
            return null
        }
    }

//    fun getRow(integer: Int): Series<String, *>? {
//        return null
//    }

//    fun join(series: Series?, strings: Array<String?>?): DataFrame? {
//        return null
//    }

    private fun getObject(value: Any?): Any? {
        return if (value != null) {
            if (value is String) {
                value as String
            } else if (value is Number) {
                value as Number
            } else if (value is Boolean) {
                value as Boolean
            } else if (value is Array<*>) {
                val array: Array<Any?> = value as Array<Any?>
                val a = arrayOfNulls<Any>(array.size)
                for (i in 0 until array.size) {
                    a[i] = getObject(array.get(i))
                }
                a
            } else if (value is Map<*,*>) {
                value as Map<String,Any?>
            } else if (value == null) {
                null
            } else {
                value.toString()
            }
        } else {
            null
        }
    }

    private fun getClass(value: Any?): KClass<*>? {
        return if (value == null) {
            null
        } else if (value is String) {
            String::class
        } else if (value is Double) {
            Double::class
        } else if (value is Boolean) {
            Boolean::class
        } else if (value is Array<*>) {
            Array::class
        } else if (value is Map<*,*>) {
            Map::class
        } else {
            String::class
        }
    }

//    companion object {
//        private val logger: java.util.logging.Logger
//            private get() = java.util.logging.Logger.getLogger(JsonDataFrame::class.java.getName())
//    }
}
