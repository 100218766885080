package com.macrofocus.common.collection

class EnumMap<K : Enum<K>,V> (val enums : Array<K>) : MutableMap<K,V?> {
    private val available = BooleanArray(enums.size)
    override val values: MutableList<V?> = ArrayList(enums.size)
//    private val array: Array<V> = Array(enums.size) {if(it > 0) null}
//    override val values: MutableList<V> = array.toMutableList()
    init {
        for (i in 0 until enums.size) {
            values.add(i, null)
        }
    }

    override val entries: MutableSet<MutableMap.MutableEntry<K, V?>>
        get() = TODO("Not yet implemented")
    override val keys: MutableSet<K>
        get() = keys.toMutableSet()
    override val size: Int
        get() = values.size
//    override val values: MutableCollection<V>
//        get() = enumMap

    override fun containsKey(key: K): Boolean {
        return true
    }

    override fun containsValue(value: V?): Boolean {
        return values.contains(value)
    }

    override fun get(key: K): V? {
        return values[key.ordinal]
    }

    override fun isEmpty(): Boolean {
        return values.isEmpty()
    }

    override fun clear() {
        TODO("Not yet implemented")
    }

    override fun put(key: K, value: V?): V? {
        values[key.ordinal] = value
        return values[key.ordinal]
    }

    override fun putAll(from: Map<out K, V?>) {
        TODO("Not yet implemented")
    }

    override fun remove(key: K): V? {
        TODO("Not yet implemented")
    }
}