package org.molap.dataframe

import org.molap.aggregates.cuboid.Cuboid
import org.molap.index.DefaultUniqueIndex
import org.molap.index.IntegerRangeUniqueIndex
import org.molap.index.UniqueIndex
import org.molap.series.MutableSeries
import org.molap.series.Series
import org.molap.series.SeriesFactory
import kotlin.js.JsExport
import kotlin.reflect.KClass

@JsExport
class DefaultDataFrame(dataFrame: DataFrame<Any?,Any?,Any?>) : AbstractDataFrame<Int, String, Any?>() {
    override val rowIndex: UniqueIndex<Int>

    override val columnIndex: UniqueIndex<String>

    val series : Array<Series<Int, Any?>>

    init {
        this.rowIndex = IntegerRangeUniqueIndex(0, dataFrame.rowCount - 1)

        val columns = ArrayList<String>(dataFrame.columnCount)
        val series = ArrayList<Series<Int,Any?>>(dataFrame.columnCount)
        var c = 0
        for (column in dataFrame.columns()) {
            val columnKey = dataFrame.getColumnKey(c)
            if(columnKey != null) {
                val toString = columnKey.toString()
                val serie : Series<Int,Any?>? = createSeries(dataFrame, column)
                if(serie != null) {
                    columns.add(toString)
                    series.add(serie)
                }
            }
            c++
        }
        this.columnIndex = DefaultUniqueIndex<String>(*columns.toTypedArray())

        this.series = series.toTypedArray()
    }

    override fun getRowClass(row: Int): KClass<*> {
        return Any::class
    }

    override fun getColumnClass(column: String): KClass<out Any> {
        return series[columnIndex.getAddress(column)].type
    }

    override fun getValueAt(row: Int, column: String): Any? {
        return series[columnIndex.getAddress(column)][row]
    }

    override fun getColumn(column: String): Series<Int, Any?>? {
        val serie = series[getColumnAddress(column)]
        return serie
    }

    private fun createSeries(dataFrame: DataFrame<Any?,Any?,Any?>, column: Any?) : Series<Int, Any?>? {
        val columnClass: KClass<out Any> = dataFrame.getColumnClass(column)
        if (columnClass == Int::class) {
            val values = IntArray(dataFrame.rowCount)
            val available = BooleanArray(dataFrame.rowCount)
            for (r in 0 until dataFrame.rowCount) {
                val valueAt = dataFrame.getValueAt(dataFrame.getRowKey(r), column) as Int?
                if (valueAt != null) {
                    available[r] = true
                    values[r] = valueAt
                } else {
                    available[r] = false
                }
            }
            return SeriesFactory.createInteger(values, available) as MutableSeries<Int, Any?>
        } else if (columnClass == Long::class) {
            val values = LongArray(dataFrame.rowCount)
            val available = BooleanArray(dataFrame.rowCount)
            for (r in 0 until dataFrame.rowCount) {
                val valueAt = dataFrame.getValueAt(dataFrame.getRowKey(r), column) as Long?
                if (valueAt != null) {
                    available[r] = true
                    values[r] = valueAt
                } else {
                    available[r] = false
                }
            }
            return SeriesFactory.createLong(values, available) as MutableSeries<Int, Any?>
        } else if (columnClass == Float::class) {
            val values = FloatArray(dataFrame.rowCount)
            val available = BooleanArray(dataFrame.rowCount)
            for (r in 0 until dataFrame.rowCount) {
                val valueAt = dataFrame.getValueAt(dataFrame.getRowKey(r), column) as Float?
                if (valueAt != null) {
                    available[r] = true
                    values[r] = valueAt
                } else {
                    available[r] = false
                }
            }
            return SeriesFactory.createFloat(values, available) as MutableSeries<Int, Any?>
        } else if (columnClass == Double::class) {
            val values = DoubleArray(dataFrame.rowCount)
            val available = BooleanArray(dataFrame.rowCount)
            for (r in 0 until dataFrame.rowCount) {
                val valueAt = dataFrame.getValueAt(dataFrame.getRowKey(r), column) as Double?
                if (valueAt != null) {
                    available[r] = true
                    values[r] = valueAt
                } else {
                    available[r] = false
                }
            }
            return SeriesFactory.createDouble(values, available) as Series<Int, Any?>
        } else {
            val values: Array<Any?> = Array<Any?>(dataFrame.rowCount, {
                val valueAt: Any? = dataFrame.getValueAt(dataFrame.getRowKey(it), column) as Any?
                if (valueAt !== Cuboid.ALL) {
                    valueAt
                } else {
                    null
                }
            })
            return SeriesFactory.create<Any?>(values, columnClass) as Series<Int, Any?>
        }
    }
}