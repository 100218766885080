package ch.ethz.icr.growup.pfe.data

object EPR {
    val ethnicNames = setOf(
        "Abkhaz",
        "Abkhazians",
        "Aboriginal people",
        "Acehnese",
        "Adibasi Janajati",
        "Adyghe",
        "Afar",
        "African Americans",
        "Africans",
        "Afrikaners",
        "Afro-Bahamians (Black)",
        "Afro-Caribbeans",
        "Afro-Colombian",
        "Afro-Costa Ricans",
        "Afro-Cubans",
        "Afro-Ecuadorians",
        "Afro-Guyanese",
        "Afro-Uruguayans",
        "Afrobrazilians",
        "Afromexicans",
        "Afronicaraguans",
        "Afropanamanians",
        "Afroperuvians",
        "Afrovenezuelans",
        "Ahmadis",
        "Ainu",
        "Aku (Creoles)",
        "Al-Akhdam",
        "Alawi",
        "Alawites",
        "Albanians",
        "Altai",
        "Amboinese",
        "American Indians",
        "Americo-Liberians",
        "Amhara",
        "Annobon Islanders",
        "Anuak",
        "Anyuak",
        "Aostans (French speakers)",
        "Arab Americans",
        "Arab Muslims",
        "Arabs",
        "Arabs/Moors",
        "Armenian Catholics",
        "Armenian Orthodox",
        "Armenians",
        "Asante (Akan)",
        "Ashkenazim (Jewish)",
        "Asian Americans",
        "Asians",
        "Assamese (non-SC/ST)",
        "Assyrians",
        "Austrians",
        "Avars",
        "Aymara",
        "Azande",
        "Azeri",
        "Baganda",
        "Bahais",
        "Bai",
        "Bakongo",
        "Balanta",
        "Balinese",
        "Balkars",
        "Baloch",
        "Baluchis",
        "Bamar (Barman)",
        "Bamileke",
        "Banda",
        "Banyarwanda",
        "Barbadians",
        "Bari",
        "Bashkirs",
        "Basoga",
        "Basques",
        "Bassa/Duala",
        "Baster",
        "Basubia",
        "Bataks",
        "Batéké",
        "Baule (Akan)",
        "Baya",
        "Bedoon",
        "Beja",
        "Bemba speakers",
        "Bembe",
        "Bengali",
        "Bengali (non-SC/ST)",
        "Bengali Hindus",
        "Bengali Muslims",
        "Beni-Shugal-Gumez",
        "Berbers",
        "Beti (and related peoples)",
        "Bhutanese",
        "Biharis (Urdu-Speaker)",
        "Birwa",
        "Black Africans",
        "Black Creoles",
        "Blacks",
        "Blacks (Mande, Peul, Voltaic etc.)",
        "Blang",
        "Bodo",
        "Bosniak/Muslims",
        "Bosniaks",
        "Bosniaks/Muslims",
        "Bougainvilleans",
        "Bouyei",
        "Bubi",
        "Buddhist Arakanese",
        "Bulgarians",
        "Bumiputera (Muslims)",
        "Bumiputera (other)",
        "Burakumin",
        "Buryats",
        "Byelorussians",
        "Cabindan Mayombe",
        "Cape Verdean",
        "Cape Verdeans",
        "Caste Hill Hindu Elite",
        "Catalans",
        "Catholics In N. Ireland",
        "Central (Chewa)",
        "Cham and Malays",
        "Chechens",
        "Cherkess",
        "Chinese",
        "Chinese (Han)",
        "Choco (Embera-Wounan)",
        "Christian Eritreans",
        "Christian lowlanders",
        "Christians",
        "Christians",
        "Chukchi",
        "Chuvashes",
        "Coloreds",
        "Coptic Christians",
        "Corsicans",
        "Côtiers",
        "Creole",
        "Creoles",
        "Crimean Tatars",
        "Croats",
        "Czechs",
        "Dai",
        "Dalits",
        "Damara",
        "Danes",
        "Dao",
        "Dargins",
        "Daur",
        "Dayak",
        "Dayaks",
        "Didinga",
        "Dinka",
        "Diola",
        "Divehis",
        "Djerma-Songhai",
        "Dominican Haitians",
        "Dominicans",
        "Dong",
        "Dongxiang",
        "Druze",
        "Dutch",
        "East Indians",
        "East Timorese",
        "Emirati Arabs",
        "English",
        "English speakers",
        "English Speakers",
        "Eshira/Bapounou",
        "Estonians",
        "Eurasians and Others",
        "European and American Jews",
        "Ewe",
        "Ewe (and related groups)",
        "Fang",
        "Far North-West Nile (Kakwa-Nubian, Madi, Lugbara, Alur)",
        "Far North-West Nilers (Madi, Lugbara, Alur)",
        "Fernandinos",
        "Fijians",
        "Fil-Chinese",
        "Finns",
        "Flemings",
        "Franco-Mauritians",
        "French",
        "French speakers",
        "Friulians",
        "Fula",
        "Fulani",
        "Fulani (and other northern Muslim peoples)",
        "Fur",
        "Ga-Adangbe",
        "Gagauz",
        "Galician",
        "Garifuna",
        "Garífuna",
        "Gelao",
        "Gens-de-Couleur",
        "Georgians",
        "German speakers (Austrians)",
        "Germans",
        "Gia Rai",
        "Gio",
        "Gorani",
        "Gorontalos",
        "Goula",
        "Greek Catholics",
        "Greek Orthodox",
        "Greeks",
        "Guaraní and other eastern indigenous groups",
        "Gujarati (non-SC/ST/OBCs)",
        "Gur",
        "Hadjerai",
        "Haitians",
        "Hani",
        "Harari",
        "Haratins (Black Moors)",
        "Hausa",
        "Hausa-Fulani and Muslim Middle Belt",
        "Hazara",
        "Herero, Mbanderu",
        "Herero/Mbanderu",
        "Highlanders",
        "Hill Tribes",
        "Himba",
        "Hindi (Non SC/ST/OBCs)",
        "Hindi-speaking Hindus",
        "Hindus",
        "Hmong",
        "Hoa (Chinese)",
        "Hui",
        "Hui (proper)",
        "Hungarians",
        "Hutu",
        "Ibadhi Muslims (Arab)",
        "Icelanders",
        "Igbo",
        "Ijaw",
        "Indian Tamils",
        "Indians",
        "Indigenous",
        "Indigenous highland peoples (Kichwa)",
        "Indigenous lowland peoples (Shuar, Achuar etc.)",
        "Indigenous peoples",
        "Indigenous Peoples",
        "Indigenous peoples",
        "Indigenous Peoples (Arawaks and Caribs)",
        "Indigenous peoples (Lenca, Maya-Chorti, Miskito, Tawahka/Sumu, Xicaque, Pech, Nahua)",
        "Indigenous peoples of the Amazon",
        "Indigenous peoples of the Andes",
        "Indigenous Tripuri",
        "Indigenous/Aboriginal Taiwanese",
        "Indo-Guyanese",
        "Ingush",
        "Irish",
        "Isaas (Somali)",
        "Ismaili Shia (South) (Arab)",
        "Israeli Arabs",
        "Italians",
        "Ja'afari Shia (Eastern Province) (Arab)",
        "Jamaicans",
        "Japanese",
        "Javanese",
        "Jewish",
        "Jews",
        "Jingpo",
        "Jordanian Arabs",
        "Kabardins",
        "KabrÃ© (and related groups)",
        "Kachins",
        "Kadazans",
        "Kakwa-Nubian",
        "Kalanga",
        "Kalenjin-Masai-Turkana-Samburu",
        "Kalmyks",
        "Kamba",
        "Kannada (non-SC/ST)",
        "Kanouri",
        "Kaonde (NW Province)",
        "Karachai",
        "Karakalpak",
        "Karakalpaks",
        "Karamojong",
        "Karelians",
        "Karenni (Red Karens)",
        "Kashmiri Muslims",
        "Kavango",
        "Kayin (Karens)",
        "Kazakh",
        "Kazakhs",
        "Kgalagadi",
        "Khakass",
        "Khmer",
        "Khmer Loeu (various indigenous minorities)",
        "Khmou",
        "Kikuyu-Meru-Emb",
        "Kinh (Vietnamese)",
        "Kirghis",
        "Kirghiz",
        "Kisii",
        "Komi",
        "Komi-Permyaks",
        "Kono",
        "Koreans",
        "Kouyou",
        "Krahn (Guere)",
        "Kru",
        "Kumyks",
        "Kuna",
        "Kunama",
        "Kurds",
        "Kurds/Yezidis",
        "Kuwaiti Shi'a (Arab)",
        "Kuwaiti Sunni (Arab)",
        "Kyrgyz",
        "Ladinos",
        "Lahu",
        "Langi/Acholi",
        "Lao (incl. Phuan)",
        "Lao Sung (excl. Hmong)",
        "Lao Tai",
        "Lao Thoeng (excl. Khmou)",
        "Lari",
        "Lari/Bakongo",
        "Latinos",
        "Latoka",
        "Latvians",
        "Lezgins",
        "Lhotsampa (Hindu Nepalese)",
        "Li",
        "Limba",
        "Lisu",
        "Lithuanians",
        "Lozi (Barotse)",
        "Luba Kasai",
        "Luba Shaba",
        "Luhya",
        "Lulua",
        "Lunda (NW Province)",
        "Lunda-Chokwe",
        "Lunda-Yeke",
        "Luo",
        "Luvale (NW Province)",
        "Luxembourgers",
        "Maasai",
        "Macedonians",
        "Madhesi",
        "Madura",
        "Mafwe",
        "Mainland Africans",
        "Mainland Chinese",
        "Mainland Muslims",
        "Makassarese and Bugis",
        "Makonde-Yao",
        "Malay",
        "Malay Muslims",
        "Malays",
        "Malinke",
        "Maltese",
        "Malyalam (non-SC/ST/OBCs)",
        "Manchu",
        "Mandingo",
        "Mandingue (and other eastern groups)",
        "Mandinka",
        "Manipuri",
        "Manjaco",
        "Mano",
        "Manyika (Shona sub-group)",
        "Maonan",
        "Maori",
        "Mapuche",
        "Marathi (non-SC/ST/OBCs)",
        "Marathis",
        "Mari",
        "Maronite Christians",
        "Maroons",
        "Masalit",
        "Maya",
        "Mbandja",
        "Mbede (Nzebi, Bateke, Obamba)",
        "Mbochi",
        "Mbochi (proper)",
        "Mbukushu",
        "Mbundu-Mestico",
        "Mende",
        "Mestizos",
        "Miao",
        "Mijikenda",
        "Minahasa",
        "Minangkabaus",
        "Miskitos",
        "Mizo",
        "Mizrahim (Jewish)",
        "Mohajirs",
        "Moldovans",
        "Mongo",
        "Mongolians",
        "Mongols",
        "Mons",
        "Montenegrins",
        "Moors (Muslims)",
        "Mordva",
        "Moro",
        "Mulam",
        "Mundari",
        "Muong",
        "Murle",
        "Muslim Arakanese",
        "Muslim Eritreans",
        "Muslim Sahel groups",
        "Muslims",
        "Mwali Comorans",
        "Myene",
        "Naga",
        "Nama",
        "Naxi",
        "Ndau (Shona sub-group)",
        "Ndebele",
        "Ndebele-Kalanga-(Tonga)",
        "Ndowe",
        "New Zealanders",
        "Newars",
        "Ngäbe-Buglé",
        "Ngalops (Drupka)",
        "Ngazidja Comorans",
        "Ngbaka",
        "Ngbaka/Mbaka",
        "Ngbandi",
        "Nkomi",
        "Non-Bumiputera (Indigenous)",
        "Non-Muslims",
        "Northern (Bariba, Peul, Ottamari, Yoa-Lokpa, Dendi, Gourmanchéma)",
        "Northern groups (Baya, Banda, Mandjia, Sara, Goula)",
        "Northern Groups (Mole-Dagbani, Gurma, Grusi)",
        "Northern Groups (Temne, Limba)",
        "Northern Muslim groups (Goula, Runga, Kara)",
        "Northern Shafi'i",
        "Northern Zaydis",
        "Northerners",
        "Northerners (Langi, Acholi, Madi, Kakwa-Nubian, Lugbara, Alur)",
        "Northerners (Langi, Acholi, Teso, Madi, Kakwa-Nubian, Lugbara, Alur)",
        "Northerners (Langi, Acholi, Teso)",
        "Northerners (Mande and Voltaic/Gur)",
        "Northerners (Tumbuka, Tonga, Ngonde)",
        "Northwestern Anglophones (Grassfielders)",
        "Norwegians",
        "Nuba",
        "Nuer",
        "Nung",
        "Nuristani",
        "Nyanja speakers (Easterners)",
        "Nzwani Comorans",
        "Ogoni",
        "Okinawans",
        "Orang Asli",
        "Oriya (non-SC/ST)",
        "Oroma",
        "Orungu",
        "Ossetes",
        "Ossetians (South)",
        "Other Akans",
        "Other Arab groups",
        "Other Backward Classes/Castes",
        "Other indigenous groups",
        "Other Kivu groups",
        "Other Muslims",
        "Other Northern groups",
        "Other Riverine groups (Banziri, Songo etc.)",
        "Other Southern groups",
        "Other Southern Nations",
        "Others Mainland (Christians and traditional religions)",
        "Ovambo",
        "Ovimbundu-Ovambo",
        "Pacific Islanders",
        "Palestinian Arabs",
        "Palestinian Arabs",
        "Palestinians (Arab)",
        "Pamir Tajiks",
        "Pamiri Tajiks",
        "Papel",
        "Papua New Guineans",
        "Papuans",
        "Pashtuns",
        "Pedi (North Sotho)",
        "Persians",
        "Peul",
        "Poles",
        "Pomaks",
        "Portuguese",
        "Protestants",
        "Protestants In N. Ireland",
        "Pulaar (Peul, Toucouleur)",
        "Punjabi",
        "Punjabi-Sikhs (non-SC/ST)",
        "Qatari Arabs",
        "Qiang",
        "Qizalbash",
        "Quechua",
        "Rapa Nui",
        "Rashaida",
        "Riverine groups (Ngbaka/Mbaka, Yakoma, Banziri etc.)",
        "Roma",
        "Romanians",
        "Romanians/Moldovans",
        "Russian speakers",
        "Russian-speakers",
        "Russians",
        "Russians (Jewish)",
        "Rusyns",
        "Saho",
        "Sahrawis",
        "Salar",
        "San",
        "Sara",
        "Sardinians",
        "Scheduled Castes",
        "Scheduled Tribes",
        "Scots",
        "Serbs",
        "Serer",
        "Shan",
        "Sharchops",
        "Shaygiyya, Ja'aliyyin and Danagla (Arab)",
        "She",
        "Shi'a Arabs",
        "Shi'a Muslims (Arab)",
        "Shilluk",
        "Shirazi (Zanzibar Africans)",
        "Shona",
        "Shona (minus Manyika & Ndau)",
        "Shona (minus Ndau)",
        "Shona-Ndau",
        "Shui",
        "Sidama",
        "Sindhi",
        "Sinhalese",
        "Slovaks",
        "Slovenes",
        "Solomon Islanders",
        "Somali",
        "Somali (Ogaden)",
        "Sotho",
        "South Sotho",
        "South-Westeners (Ankole, Banyoro, Toro, Banyarwanda)",
        "South-Westerners (Ankole, Banyoro, Toro)",
        "South/Central (Fon)",
        "Southeastern (Yoruba/Nagot and Goun)",
        "Southern Mande",
        "Southern Shafi'i",
        "Southern Yemenis",
        "Southerners",
        "Southerners (Lomwe, Mang'anja, Nyanja, Yao)",
        "Southwestern (Adja)",
        "Southwestern Anglophones (Bakweri etc.)",
        "Spanish",
        "Sri Lankan Tamils",
        "Sumus",
        "Sundanese",
        "Sunni Arabs",
        "Sunni Shafi'I (Arab)",
        "Sunni Shafii/Sofi (Hijazi) (Arab)",
        "Sunni Wahhabi (Najdi) (Arab)",
        "Sunnis (Arab)",
        "Susu",
        "Swazi",
        "Swedes",
        "Swiss French",
        "Swiss Germans",
        "Swiss Italians",
        "Taiwanese",
        "Tajiks",
        "Talysh",
        "Tama",
        "Tamil (non-SC/ST)",
        "Tamils and Telugus",
        "Tatars",
        "Tay",
        "Telugu (Non SC/ST/OBCs)",
        "Temne",
        "Ternate",
        "Teso",
        "Tetela-Kusu",
        "Thai",
        "Thai-Lao",
        "Tibetans",
        "Tibetians",
        "Tigry",
        "Tiv",
        "Tonga-Ila-Lenje (Southerners)",
        "Toposa",
        "Toubou",
        "Tribal-Buddhists",
        "Tsonga",
        "Tsonga-Chopi",
        "Tswana",
        "Tswapong",
        "Tu",
        "Tuareg",
        "Tujia",
        "Tupi-Guaraní and other indigenous groups",
        "Turkish",
        "Turkmen",
        "Turkmens",
        "Turks",
        "Tutsi",
        "Tutsi-Banyamulenge",
        "Tuvinians",
        "Udmurt",
        "Uighur",
        "Uighurs",
        "Ukrainians",
        "Uyghur",
        "Uzbeks",
        "Venda",
        "Vietnamese",
        "Vili",
        "Vlachs",
        "Wa",
        "Walloon",
        "Welsh",
        "White",
        "White Moors (Beydan)",
        "White Zimbabweans",
        "Whites",
        "Whites/mestizos",
        "Wolof",
        "Xhosa",
        "Xibe",
        "Xinca",
        "Yakoma",
        "Yakuts",
        "Yao",
        "Yeyi",
        "Yezidis",
        "Yi",
        "Yoruba",
        "Zaghawa",
        "Zaghawa, Bideyat",
        "Zanzibar Arabs",
        "Zaydis",
        "Zhuang",
        "Zomis (Chins)",
        "Zoroastrians",
        "Zulu"
    )
}