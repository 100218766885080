package org.mkui.color.crossplatform

interface IColor {
    /**
     * Returns the red component in the range 0-255 in the default sRGB
     * space.
     * @return the red component.
     * @see .getRGB
     */
    fun getRed(): UByte

    /**
     * Returns the green component in the range 0-255 in the default sRGB
     * space.
     * @return the green component.
     * @see .getRGB
     */
    fun getGreen(): UByte

    /**
     * Returns the blue component in the range 0-255 in the default sRGB
     * space.
     * @return the blue component.
     * @see .getRGB
     */
    fun getBlue(): UByte

    /**
     * Returns the alpha component in the range 0-255.
     * @return the alpha component.
     * @see .getRGB
     */
    fun getAlpha(): UByte

    fun toHTMLColor(): String {
        var alpha: String
        var red: String = getRed().toString(16)
        var blu: String = getBlue().toString(16)
        var gre: String = getGreen().toString(16)
        if (getAlpha() == 255u.toUByte()) {
            alpha = ""
        } else {
            alpha = getAlpha().toString(16)
            alpha = if (alpha.length == 1) "0$alpha" else alpha
        }
        red = if (red.length == 1) "0$red" else red
        blu = if (blu.length == 1) "0$blu" else blu
        gre = if (gre.length == 1) "0$gre" else gre
        return "#$alpha$red$gre$blu"
    }

    fun toJavaScriptColor(): String {
        return if (getAlpha() == 255u.toUByte()) {
            toHTMLColor()
        } else {
            "rgba(" + getRed().toString() + "," + getGreen().toString() + "," + getBlue().toString() + "," + (getAlpha().toFloat() / 255.0).toString() + ")"
        }
    }
}
