package org.mkui.palette

enum class Cycle {
    /**
     * Use the terminal colors to fill the remaining area.
     */
    NONE,

    /**
     * Cycle the gradient colors start-to-end, end-to-start to fill the remaining area.
     */
    REPEAT,

    /**
     * Cycle the gradient colors start-to-end, start-to-end to fill the remaining area.
     */
    REFLECT
}