package com.macrofocus.common.interval

/**
 * A closed interval is an interval that includes all of its limit points.
 */
abstract class ClosedInterval : AbstractMutableInterval() {
    override var extent: Double
        get() = end - start
        set(value) {TODO("Not yet implemented!")}

    override fun contains(value: Double): Boolean {
        return start <= value && end >= value
    }

    override fun containsStartEnd(start: Double, end: Double): Boolean {
        return contains(start) && contains(end)
    }

    override fun containsInterval(interval: Interval?): Boolean {
        return contains(interval!!.start) && contains(interval.end)
    }

    override fun overlaps(start: Double, end: Double): Boolean {
        return contains(start) || contains(end)
    }

    override fun overlapsInterval(interval: Interval?): Boolean {
        return contains(interval!!.start) || contains(interval.end)
    }

    override val isInverted: Boolean
        get() = end < start
    override val isDegenerate: Boolean
        get() = extent == 0.0
}
