@file:JvmName("MkColor2")
@file:JvmMultifileClass

package org.mkui.color

import org.mkui.color.crossplatform.HSBtoRGB
import org.mkui.color.crossplatform.RGBtoHSB
import kotlin.jvm.JvmMultifileClass
import kotlin.jvm.JvmName
import kotlin.math.max
import kotlin.math.min

expect class MkColor

expect fun MkColor.getURed(): UByte

expect fun MkColor.getUGreen(): UByte

expect fun MkColor.getUBlue(): UByte

expect fun MkColor.getUAlpha(): UByte

expect fun colorOf(r: UByte, g: UByte, b: UByte, a: UByte): MkColor

expect fun colorOf(r: UByte, g: UByte, b: UByte): MkColor

expect fun colorOf(r: Int, g: Int, b: Int): MkColor

expect fun colorOf(r: Int, g: Int, b: Int, a: Int): MkColor

expect fun colorOf(rgba: Int) : MkColor

expect fun colorOf(hex: String) : MkColor

expect fun colorOf(r: Float, g: Float, b: Float): MkColor

expect fun colorOf(r: Float, g: Float, b: Float, a: Float): MkColor

fun MkColor.brighter(): MkColor {
    val FACTOR = 0.7
    var r = getURed().toInt()
    var g = getUGreen().toInt()
    var b = getUBlue().toInt()
    val alpha = getUAlpha().toInt()

    /* From 2D group:
         * 1. black.brighter() should return grey
         * 2. applying brighter to blue will always return blue, brighter
         * 3. non pure color (non zero rgb) will eventually return white
         */

    /* From 2D group:
         * 1. black.brighter() should return grey
         * 2. applying brighter to blue will always return blue, brighter
         * 3. non pure color (non zero rgb) will eventually return white
         */
    val i = (1.0 / (1.0 - FACTOR)).toInt()
    if (r == 0 && g == 0 && b == 0) {
        return colorOf(i, i, i, alpha)
    }
    if (r > 0 && r < i) r = i
    if (g > 0 && g < i) g = i
    if (b > 0 && b < i) b = i

    return colorOf(
        min((r / FACTOR).toInt(), 255),
        min((g / FACTOR).toInt(), 255),
        min((b / FACTOR).toInt(), 255),
        alpha
    )

}

fun MkColor.alpha(alpha: Float): MkColor {
    return colorOf(this.getURed(), this.getUGreen(), this.getUBlue(), (255 * alpha).toUInt().toUByte())
}

fun MkColor.darker(): MkColor {
    val FACTOR = 0.7
    return colorOf(
        max((this.getURed().toDouble() * FACTOR).toInt(), 0),
        max((this.getUGreen().toDouble() * FACTOR).toInt(), 0),
        max((this.getUBlue().toDouble() * FACTOR).toInt(), 0),
        this.getUAlpha().toInt()
    )
}

fun MkColor.brightenAndSaturate(brightness: Float, saturation: Float): MkColor {
    val hsb: FloatArray = RGBtoHSB(getURed().toInt(), getUGreen().toInt(), getUBlue().toInt(), null)

    if (brightness != 0f) {
        hsb[2] = max(0.0f, min(1.0f, hsb[2] + brightness))
    }

    if (saturation != 0f) {
        hsb[1] = max(0.0f, min(1.0f, hsb[1] + saturation))
    }

//            hsb[2] = Math.max(0.0f, Math.min(1.0f, hsb[2] * (1.0f + (brightness / 100f))));


//            hsb[2] = Math.max(0.0f, Math.min(1.0f, hsb[2] * (1.0f + (brightness / 100f))));
    return colorOf(HSBtoRGB(hsb[0], hsb[1], hsb[2]))
}

