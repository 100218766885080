package org.mkui.color

import org.mkui.color.crossplatform.CPColor

actual typealias MkColor = CPColor

actual fun MkColor.getURed(): UByte {
    return r
}

actual fun MkColor.getUGreen(): UByte {
    return g
}

actual fun MkColor.getUBlue(): UByte {
    return b
}

actual fun MkColor.getUAlpha(): UByte {
    return a
}

actual fun colorOf(r: UByte, g: UByte, b: UByte, a: UByte): MkColor {
    return MkColor(r, g, b, a)
}

actual fun colorOf(r: UByte, g: UByte, b: UByte): MkColor {
    return MkColor(r, g, b)
}

actual fun colorOf(r: Int, g: Int, b: Int): MkColor {
    return MkColor(r, g, b)
}

@JsExport
actual fun colorOf(r: Int, g: Int, b: Int, a: Int): MkColor {
    return MkColor(r, g, b, a)
}

actual fun colorOf(hex: String) : MkColor {
    return colorOf(hex.removePrefix("#").toInt(16))
}

actual fun colorOf(rgba: Int) : MkColor {
    return MkColor(rgba)
}

actual fun colorOf(r: Float, g: Float, b: Float) : MkColor {
    return MkColor(r, g, b)
}

actual fun colorOf(r: Float, g: Float, b: Float, a: Float) : MkColor {
    return MkColor(r, g, b, a)
}

