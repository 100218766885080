package org.mkui.font

actual class CPFontFactory {
    actual fun createFont(family: String?, style: Weight, size: Int): MkFont? {
        return MkFont(family!!, size.toDouble(), weight = style)
    }
    actual fun createFont(family: String?, style: Int, size: Int): MkFont? {
        return MkFont(family!!, size.toDouble())
    }

    actual fun decodeFont(labelingFont: String?): MkFont? {
        return MkFont(labelingFont!!, 12.0)
    }

    actual fun createFontFamily(family: String?): MkFont? {
        return MkFont(family!!, 12.0)
    }

    actual fun decodeFontWithDefault(
        labelingFont: String?,
        defaultLabelingFont: MkFont?
    ): MkFont? {
        val f: MkFont? = decodeFont(labelingFont)
        return f ?: defaultLabelingFont
    }

    actual fun createDefaultFont(): MkFont {
        return createFontFamily("Arial")!!
    }

    actual companion object {
        actual val instance: CPFontFactory = CPFontFactory()
    }

}