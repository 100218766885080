package ch.ethz.icr.growup.pfe.page

import ch.ethz.icr.growup.Box
import ch.ethz.icr.growup.Country
import ch.ethz.icr.growup.pfe.context.useAppState
import ch.ethz.icr.growup.pfe.router.useViewIdParam
import com.macrofocus.common.properties.MutableProperty
import com.macrofocus.common.properties.SimpleProperty
import emotion.styled.styled
import js.objects.jso
import js.uri.decodeURIComponent
import js.uri.encodeURIComponent
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromDynamic
import mui.icons.material.Close
import mui.material.*
import mui.system.Box
import mui.system.sx
import org.jetbrains.letsPlot.commons.intern.math.toRadians
import org.mkui.react.set
import org.mkui.react.toState
import org.w3c.fetch.RequestInit
import react.*
import react.dom.html.ReactHTML
import react.router.dom.NavLink
import web.cssom.Color
import web.cssom.None
import web.cssom.Position
import web.cssom.px
import kotlin.math.*
import mui.icons.material.Menu as MenuIcon

val Sidebar = FC<Props> {
    val isMobile = useIsMobile()
    var isOpen by useState(false)

    Box {
        if (!isMobile) {
            sx {
                gridArea = Area.Sidebar
            }
        }

        component = ReactHTML.nav

        if (isMobile) {
            SwipeableDrawer {
                anchor = DrawerAnchor.left
                open = isOpen
                onOpen = { isOpen = true }
                onClose = { isOpen = false }

                CountriesList()
            }

            SpeedDial {
                sx {
                    position = Position.absolute
                    bottom = 80.px
                    left = 16.px
                }

                ariaLabel = "Menu"

                icon = MenuIcon.create()
                onClick = { isOpen = true }
            }
        } else {
            Drawer {
                variant = DrawerVariant.permanent
                anchor = DrawerAnchor.left

                CountriesList()
            }
        }
    }
}

fun calculateZoomLevelFromDistance(distance: Double, mapWidth: Int, mapHeight: Int): Double {
    val metersPerPixel = distance / max(mapWidth, mapHeight)
    return ln(40008000.0 / metersPerPixel) / ln(2.0) - 8
}
fun haversineDistance(bbox: Box): Double {
    val minLon = bbox.urtX
    val minLat = bbox.urtY
    val maxLon = bbox.llbX
    val maxLat = bbox.llbY
    val R = 6371.0 // Radius of the Earth in kilometers
    val dLat = toRadians(maxLat - minLat)
    val dLon = toRadians(maxLon - minLon)
    val a = sin(dLat / 2.0) * sin(dLat / 2.0) +
            cos(toRadians(minLat)) * cos(toRadians(maxLat)) *
            sin(dLon / 2.0) * sin(dLon / 2.0)
    val c = 2.0 * atan2(sqrt(a), sqrt(1 - a))
    return R * c // Distance in kilometers
}

fun calculateZoomLevel(bbox: Box, width: Int, height: Int): Double {
    val minLon = bbox.urtX
    val minLat = bbox.urtY
    val maxLon = bbox.llbX
    val maxLat = bbox.llbY

    val longitudeDiff = abs(maxLon - minLon)
    val latitudeDiff = abs(maxLat - minLat)

    val aspectRatio = width / height

    val zoomLongitude = log2(360.0 / longitudeDiff / aspectRatio)
    val zoomLatitude = log2(180.0 / latitudeDiff)
    println("$longitudeDiff $latitudeDiff $aspectRatio -> $zoomLongitude,$zoomLatitude")

    return min(zoomLongitude, zoomLatitude)
}

private val CountriesList = FC {
    // In case this is diplayed for the home page, then we link it to the PFE view
    val viewId = useViewIdParam().ifBlank { "pfe" }
    val appState = useAppState()
    val (countryIdParam, setCountryIdParam) = appState.countryId.toState()
    val (countries, _) = appState.countries.toState()

    var country by useState<Country>()

    Box {
        Toolbar()

        if(countryIdParam != "") {
            IconButton {
                onClick = {
                    setCountryIdParam("")
                    appState.country.value = null
                    appState.zoom.value = 1.1
                    appState.longitude.value = 0.0
                    appState.latitude.value = 47.021278030856564
                }

                Close {
                }
            }
        }

        List {
            dense = true

            sx {
                width = Sizes.Sidebar.Width
            }

            countries?.forEach {
                val key = it.gwid
                val countryId = it.countryname
                val country = it

                LinkButton {
                    to = "${encodeURIComponent(viewId)}/${encodeURIComponent(countryId)}"
                    onClick = {
                        appState.zoomOnCountry(country)
                    }

                    ListItemButton {
                        dense = true

                        sx {
                            marginTop = 1.px
                            marginBottom = 1.px
                            paddingTop = 1.px
                            paddingBottom = 1.px
                        }

                        val decodeURIComponent = decodeURIComponent(countryIdParam)
                        selected = decodeURIComponent == countryId
                        autoFocus = selected

                        ListItemText {
                            sx {
                                marginTop = 1.px
                                marginBottom = 1.px
                            }

                            primary = ReactNode(countryId)
                        }
                    }
                }
            }
        }
    }
}

val LinkButton = NavLink.styled {
    textDecoration = None.none
    color = Color.currentcolor
}

