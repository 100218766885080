package com.macrofocus.common.interval

import com.macrofocus.common.properties.MutableProperty
import com.macrofocus.common.properties.PropertyEvent
import com.macrofocus.common.properties.PropertyListener
import com.macrofocus.common.properties.SimpleProperty

/**
 * A closed interval is an interval that includes all of its limit points.
 */
open class PropertyClosedInterval(start: Double, extent: Double) : ClosedInterval() {
    override val startProperty: MutableProperty<Double> = SimpleProperty(start)
    override val endProperty: MutableProperty<Double> = SimpleProperty(startProperty.value + extent)

    init {
        startProperty.addPropertyListener(object : PropertyListener<Double> {
            override fun propertyChanged(event: PropertyEvent<Double>) {
                val e = IntervalEvent(event.oldValue, end, end - event.oldValue)
                e.setNewValues(start, end, extent)
                notifyIntervalChanged(e)
            }
        })
        endProperty.addPropertyListener(object : PropertyListener<Double> {
            override fun propertyChanged(event: PropertyEvent<Double>) {
                val e = IntervalEvent(start, event.oldValue, event.oldValue - start)
                e.setNewValues(start, end, extent)
                notifyIntervalChanged(e)
            }
        })
    }

    override var start: Double
        get() = startProperty.value
        set(value) {
            startProperty.value = value
        }
    override var end: Double
        get() = endProperty.value
        set(value) {
            endProperty.value = value
        }

    override var extent: Double
        get() = super.extent
        set(extent) {
            endProperty.value = start + extent
        }

    override fun setValue(value: Double, extent: Double) {
        startProperty.value = value
        endProperty.value = value + extent
        ////        System.err.println(value + ", " extent + " / " + _);
//        if (getStart() != value || getExtent() != extent) {
//            final IntervalEvent event = new IntervalEvent(getStart(), getEnd(), getExtent());
//            start.setValue(value);
//            end.setValue(value + extent);
//            event.setNewValues(getStart(), getEnd(), getExtent());
//
//            notifyIntervalChanged(event);
//        }
    }

    override fun toString(): String {
        return "PropertyClosedInterval{" +
                "start=" + start +
                ", end=" + end +
                ", extent=" + extent +
                '}'
    }
}
