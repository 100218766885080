package org.mkui.react

import com.macrofocus.common.properties.MutableProperty
import com.macrofocus.common.properties.PropertyEvent
import com.macrofocus.common.properties.PropertyListener
import com.macrofocus.common.selection.*
import react.*
import kotlin.reflect.KProperty

typealias SetState<T> = (value: T) -> Unit

fun <T> MutableProperty<T>.toState() : ReactStateInstance<T> {
    val pair = useState { this.value }
//    val (getValue, setStateValue) = pair
    val setStateValue: StateSetter<T> = pair.component2()
    val setPropertyValue : SetState<T> = { this.value = it }

    val property = this

    useEffectWithCleanup {
        val listener = object : PropertyListener<T> {
            override fun propertyChanged(event: PropertyEvent<T>) {
//                if(DEBUG) println("Property value changed from " + event.oldValue + " to " + event.newValue)
                setStateValue(event.newValue)
            }
        }
        property.addPropertyListener(listener)

        onCleanup { property.removePropertyListener(listener) }
    }

    return ReactStateInstance(pair, setPropertyValue)
}

fun <T> MutableSelection<T>.toState() : Pair<Array<T>, SetState<Array<T>>> {
    val pair = useState {this.toList().toTypedArray()}
    val (getValue, setStateValue) = pair

    val setPropertyValue : SetState<Array<T>> = { this.setSelectedElements(*it)}

    useEffectWithCleanup {
        val listener = object : SelectionListener<T> {
            override fun selectionChanged(event: SelectionEvent<T>) {
                setStateValue(event.model.toList().toTypedArray())
            }
        }
        this@toState.addSelectionListener(listener)
        onCleanup { this@toState.removeSelectionListener(listener) }
    }
    return Pair(getValue, setPropertyValue)
}

fun <T> MutableSingleSelection<T>.toState() : Pair<T?, SetState<T>> {
    val pair = useState {this.selected }
    val (getValue, setStateValue) = pair

    val setPropertyValue : SetState<T> = { this.selected = it }

    useEffectWithCleanup {
        val listener = object : SingleSelectionListener<T> {
            override fun selectionChanged(event: SingleSelectionEvent<T>) {
                setStateValue(event.model.selected)
            }
        }
        this@toState.addSingleSelectionListener(listener)
        onCleanup { this@toState.removeSingleSelectionListener(listener) }
    }
    return Pair(getValue, setPropertyValue)
}

class ReactStateInstance<T>(val state: StateInstance<T>, val setState : SetState<T>) {
    inline operator fun component1(): T = state.component1()

    inline operator fun component2(): (T) -> Unit = setState

    inline operator fun getValue(
        thisRef: Nothing?,
        property: KProperty<*>,
    ): T =
        state.component1()

    inline operator fun setValue(
        thisRef: Nothing?,
        property: KProperty<*>,
        value: T,
    ) {
        state.component2().invoke(value)
    }
}
