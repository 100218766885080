package ch.ethz.icr.growup.pfe.view

import ch.ethz.icr.growup.pfe.color
import mui.material.Box
import mui.material.Card
import mui.material.CardContent
import mui.material.Typography
import mui.material.styles.TypographyVariant.Companion.body1
import mui.material.styles.TypographyVariant.Companion.h5
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import web.cssom.Display.Companion.inlineBlock
import web.cssom.VerticalAlign

val AboutView = FC<Props> {
    Card {
        CardContent {
            Typography {
                color = "text.secondary"
                gutterBottom = true
                variant = h5
                +"What is this?"
            }
            Typography {
                component = div
                variant = body1

                +"The GROW"
                Up()
                +" platform offers a visualization of settlement patterns of politically active ethnic groups around the world from 1946-2020. Additionally, it provides information about ethnic groups' access to executive government power, their involvement in civil war, federal administrative units, physical elevation, nightlight data, as well as population and GDP data by area."
            }

            Typography {
                color = "text.secondary"
                gutterBottom = true
                variant = h5
                +"Who made this?"
            }
            Typography {
                component = div
                variant = body1

                +"The GROW"
                Up()
                +"  project has been led since its inception by Luc Girardin. During its development, it has greatly benefited from the contributions of Philipp Hunziker, Lars-Erik Cederman, Nils-Christian Bormann, Seraina Rüegger, and Manuel Vogt. Many other people have contributed to its content and form over time, and our gratitude goes to our colleagues at the International Conflict Research group, our project partners, country experts, geocoders, and colleagues who made this possible."
            }

            Typography {
                color = "text.secondary"
                gutterBottom = true
                variant = h5
                +"How can I cite it?"
            }
            Typography {
                component = div
                variant = body1

                +"If you would like to cite GROWᵁᴾ in your own work, please use the following citation model:\n"
                +"\n"
                +"Luc Girardin, Philipp Hunziker, Lars-Erik Cederman, Nils-Christian Bormann, Seraina Rüegger, and Manuel Vogt. 2015. GROWᵁᴾ - Geographical Research On War, Unified Platform. ETH Zurich. http://growup.ethz.ch/\n"
                +"and use the citation generated by the Research Front End.\n"
            }

            Typography {
                color = "text.secondary"
                gutterBottom = true
                variant = h5
                +"Who made this?"
            }
            Typography {
                component = div
                variant = body1

                +"This is the only data that codes dynamic settlement patterns. The Russian intervention in Georgia in 2009, for example, led to the expulsion of Georgians from Abkhazia and South Ossetia, a change that is reflected in the data set. Another dynamic characteristic is the change in the international system. The break-up of the Soviet Union or former Yugoslavia is an example where ethnic groups now live in new states and possibly changed their settlement patterns within these new states."
            }

            Typography {
                color = "text.secondary"
                gutterBottom = true
                variant = h5
                +"What is special about this?"
            }
            Typography {
                component = div
                variant = body1

                +"Research on civil war has identified a number of factors that explain civil war. Exclusion from executive power, poor states, populated countries, and inaccessible terrain are all associated with an increased risk of civil war."
            }

            Typography {
                color = "text.secondary"
                gutterBottom = true
                variant = h5
                +"Why are these specific pieces of information provided?"
            }
            Typography {
                component = div
                variant = body1

                +"Research on civil war has identified a number of factors that explain civil war. Exclusion from executive power, poor states, populated countries, and inaccessible terrain are all associated with an increased risk of civil war."
            }

            Typography {
                color = "text.secondary"
                gutterBottom = true
                variant = h5
                +"Why do I not see all groups that are listed?"
            }
            Typography {
                component = div
                variant = body1

                +"Some groups, like Whites or Blacks in the United States, virtually live across the entire territory of a state. Even if there is a larger concentration in some part of the country, it was not obvious that members of these groups were completely absent from a given area. Alternatively, some groups live exclusively in cities. In either case, a group is not assigned a settlement area."
            }

            Typography {
                color = "text.secondary"
                gutterBottom = true
                variant = h5
                +"What about cities?"
            }
            Typography {
                component = div
                variant = body1

                +"Big cities attract a lot of diverse people. While we have indicated whether members of a specific ethnic group live in cities they are not coded geographically."
            }

            Typography {
                color = "text.secondary"
                gutterBottom = true
                variant = h5
                +"How are ethnic groups defined?"
            }
            Typography {
                component = div
                variant = body1

                +"An ethnic group is defined as an identity group that defines itself or is defined by others along linguistic, religious or racial characteristics. Examples include the Basques in Spain (mainly language), the Muslims in India (mainly religion), or Whites in South Africa (mainly race or phenotype)."
            }

            Typography {
                color = "text.secondary"
                gutterBottom = true
                variant = h5
                +"What does \"politically relevant\" mean?"
            }
            Typography {
                component = div
                variant = body1

                +"Politically relevant groups are those that either have representatives making political claims on behalf of their group or those that are singled out by the state through discrimination. Moreover, sometimes groups form ethnic alliances along different ethnic markers. Eritrean Muslims in Ethiopia before 1993 are a case in point. After Eritrean independence the Muslims split along linguistic lines and formed new politically relevant groups."
            }

            Typography {
                color = "text.secondary"
                gutterBottom = true
                variant = h5
                +"What is access to executive government power and why is it important?"
            }
            Typography {
                component = div
                variant = body1

                +"If a group makes political claims it can often best advance these in government positions. Being excluded from government positions does not only hamper the effective advancements of political demands but also implies a symbolic exclusion from state power. Exclusion from government is associated with a higher risk of civil war.\n"
                        +"\n"
                        +"Included into the executive:\n"
                        +"\n"
                        + "Monopoly (M) - Elite members hold monopoly power in the executive to the exclusion of members of other ethnic groups.\n"
                        + "Dominant (D) - Elite members of the group hold dominant power in the executive but there is limited inclusion of \"token\" members of other groups.\n"
                        + "Senior Partner (S) - Representatives of the group participate as senior partners in a formal or informal power-sharing arrangement.\n"
                        + "Junior Partner (J) - Representatives participate as junior partners in government.\n"
                        + "Excluded from the executive:\n"
                        + "\n"
                        + "Self-Exclusion (s) - The special category of self-exclusion applies to groups that have excluded themselves from central state power, in the sense that they control a particular territory of the state which they ha declared independent from the central government.\n"
                        + "Powerless (P) - Elite representatives hold no political power at either the national or the regional level without being explicitly discriminated against.\n"
                        + "Discriminated (d) - Group members are subjected to active, intentional, and targeted discrimination, with the intent of excluding them from both regional and national power.\n"
                        + "State Collapse (C)\n"
                        + "Irrelevant (I)"
            }
        }
//        CardActions {
//            Button {
//                size = Size.small
//                +"Learn More"
//            }
//        }
    }
}

val Up = FC<Props> {
    +"ᵁᴾ"
//    Box {
//        component = span
//        sx {
//            display = inlineBlock
//            verticalAlign = VerticalAlign.`super`
//        }
//
//        +"up"
//    }
}