package ch.ethz.icr.growup.pfe.view

import react.FC
import react.Props

data class View (
    val key: String,
    val name: String,
    val Component: FC<Props>,
    val icon: react.ReactNode?
)