package ch.ethz.icr.growup.pfe.theme

import react.*

val YearContext: RequiredContext<StateInstance<Int>> =
    createRequiredContext()

fun useYear(): Int =
    useRequiredContext(YearContext).component1()

fun useSetYear(): StateSetter<Int> =
    useRequiredContext(YearContext).component2()