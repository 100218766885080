package ch.ethz.icr.growup.pfe.page

import ch.ethz.icr.growup.pfe.theme.useSetYear
import ch.ethz.icr.growup.pfe.theme.useYear
import js.objects.jso
import mui.material.*
import mui.system.sx
import react.FC
import react.ReactNode
import react.dom.html.ReactHTML
import web.cssom.plus
import web.cssom.px
import web.cssom.vw
import web.events.Event

val Footer = FC {
    BottomNavigation {
        sx {
            gridArea = Area.Footer
            height = Sizes.Footer.Height
        }
        component = ReactHTML.footer
        showLabels = true

        BottomNavigationAction {
            label = ReactNode("Feedback")
        }

        BottomNavigationAction {
            label = ReactNode("© 2015-2024 ETH Zurich")
        }
    }

//    val navigate = useNavigate();
//
//    BottomNavigation {
//        val views = useLoaderData().unsafeCast<Array<out View>>()
//        val viewId = useViewIdParam()
//
//        value = decodeURIComponent(viewId)
//
//        onChange = { _, key ->
//            navigate(encodeURIComponent(key as String))
//        }
//
//        for ((key, name, _, icon) in views.filter { it.icon != null }) {
//                BottomNavigationAction {
//                    label = ReactNode(name)
//                    this.icon = icon
//                    value = decodeURIComponent(key)
//            }
//        }
//    }
}