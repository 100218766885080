package com.macrofocus.common.properties

import kotlin.properties.ReadWriteProperty
import kotlin.reflect.KProperty

open class KReadWriteProperty<T>(property: MutableProperty<T>) : ReadWriteProperty<Any?, T> {
    private var property = property

    override fun getValue(thisRef: Any?, property: KProperty<*>): T {
        return this.property.value
    }

    override fun setValue(thisRef: Any?, property: KProperty<*>, value: T) {
        this.property.value = value
    }
}