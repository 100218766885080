package ch.ethz.icr.growup.pfe.view

import ch.ethz.icr.growup.pfe.context.useAppState
import ch.ethz.icr.growup.pfe.data.EPR
import ch.ethz.icr.growup.pfe.theme.useYear
import com.macrofocus.common.json.JsonObject
import com.macrofocus.common.json.get
import deckgl.DeckGL
import deckgl.FlyToInterpolator
import deckgl.Layer
import deckgl.MVTLayer
import emotion.react.css
import js.objects.jso
import map.ReactMapGL
import org.mkui.colormap.ColorMapFactory
import org.mkui.palette.PaletteFactory
import org.mkui.react.toState
import react.*
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.router.useNavigate
import web.cssom.Display
import web.cssom.Position.Companion.absolute
import web.cssom.pct
import web.cssom.px
import web.html.HTMLDivElement
import kotlin.time.measureTime


external interface Layer {
}
external interface Paint {
}

fun interpolateColor(value: Number, color1: Array<Int>, color2: Array<Int>, min: Double, max: Double, alpha: Int): Array<Int> {
    val normalized = (value.toDouble() - min) / (max - min)
    val r = color1[0] + (color2[0] - color1[0]) * normalized
    val g = color1[1] + (color2[1] - color1[1]) * normalized
    val b = color1[2] + (color2[2] - color1[2]) * normalized
    return arrayOf(r.toInt(), g.toInt(), b.toInt(), alpha)
}

val MainView = FC<Props> {
    val navigate = useNavigate()

    val targetRef = useRef<HTMLDivElement>()

    val MAPLIBRE = false

    val paletteFactory = PaletteFactory.instance
    val colorMapFactory = ColorMapFactory(paletteFactory)

    ReactHTML.div {
        ref = targetRef

        val year = useYear()
        val (countryId, _) = useAppState().countryId.toState()
        val (countries, _) = useAppState().countries.toState()
        val (countryVariable, _) = useAppState().countryVariable.toState()
        val (groupVariable, _) = useAppState().groupVariable.toState()
        val (level, _) = useAppState().level.toState()
        val appState = useAppState()

        val longitude by appState.longitude
        val latitude by appState.latitude
        val zoom by appState.zoom

        val onViewStateChange = { state: dynamic ->
//            println("ViewState ${viewState.viewState.longitude},${viewState.viewState.latitude},${viewState.viewState.zoom}")
//            println("ViewState ${viewState.viewState.width}x${viewState.viewState.height}")
           // setViewState(viewState.viewState)
//            viewState.width.value = state.viewState.width
//            viewState.height.value = state.viewState.height
            appState.longitude.value = state.viewState.longitude
            appState.latitude.value = state.viewState.latitude
            appState.zoom.value = state.viewState.zoom

            appState.asDynamic().transitionDuration = 2000

            state.viewState
        }

        useMemo(countries, countryId) {
            println("useEffect request zooming on $countryId")

            if(countries != null) {
                val country = countries.find { it.countryname == countryId }
                if (country != null) {
                    println("Zooming on country $country")
                    appState.zoomOnCountry(country)
                }
            }
        }

        css {
//            position = Position.absolute
//            this.width = 100.pct
//            this.height = 100.pct
        }

//        val INITIAL_VIEW_STATE: dynamic = jso {
//            latitude = 47.021278030856564
//            longitude = 7.969664962869274
//            zoom = 1.0
//            pitch = 0
//            bearing = 0
//
//            maxZoom = 16
////        transitionDuration = 1000
////        onViewStateChange = callback
////        onLoad = onLoadCallback
//        }

        val layers: Array<Layer>
        measureTime {
            val naturalEarth2Layer = MVTLayer<Any>(jso {
                id = "natural_earth_2-layer"
                this.data = "/natural_earth_2"

                asDynamic().stroked = true
//                asDynamic().filled = false
//                asDynamic().getLineColor = arrayOf(192, 192, 192)
//                opacity = 0.7
//
//                asDynamic().lineWidthMinPixels = 1
            })

            val countryPalette = paletteFactory.createDefaultSequentialPalette()
            val countryColormap = colorMapFactory.createContinuousColorMap(0.0, 1.0, countryPalette)

            val countryStrokeColorFunction = { d: dynamic ->
                val countryName = d.properties.Name as String?
                if (countryName == countryId) {
                    arrayOf(255, 0, 0)
                } else {
                    arrayOf(63, 63, 63)
                }
            }
            val countryFillColorFunction = { d: dynamic ->
                if(countryVariable != null && level == "country") {
                    val properties = d.properties.unsafeCast<JsonObject>()
                    val value = properties.get(countryVariable) as Number?
                    if(value != null) {
                        val color1 = arrayOf(0, 255, 0) // Blue
                        val color2 = arrayOf(255, 0, 0) // Red

                        val color = interpolateColor(value, color1, color2, 0.0, 1.0, 255)
//                        val color = countryColormap.getColor(doubleValue)
                        if(color != null) {
//                            arrayOf(color.getRed().toInt(), color.getGreen().toInt(), color.getBlue().toInt())
                            color
                        } else {
                            arrayOf(255, 140, 0, 0)
                        }
                    } else {
                        arrayOf(255, 140, 0, 0)
                    }
                } else {
                    val countryName = d.properties.Name as String?
                    if (countryName == countryId) {
                        arrayOf(255, 0, 0)
                    } else {
                        arrayOf(255, 140, 0, 0)
                    }
                }
            }
            val countryLineWidthFunction = { d: dynamic ->
                val countryName = d.properties.Name as String?
                if (countryName == countryId) {
                    12
                } else {
                    1
                }
            }
            println("Recreated countryFillColorFunction $countryFillColorFunction")

            val countriesLayer = MVTLayer<Any>(jso {
                id = "countries-layer-$year"
                this.data = "/geocountryperiods/$year"

                asDynamic().stroked = true
                asDynamic().filled = true
//                asDynamic().getFillColor = arrayOf(255, 140, 0, 0)
                asDynamic().getFillColor = countryFillColorFunction
                asDynamic().getLineColor = arrayOf(63, 63, 63)
                asDynamic().getLineColor = countryStrokeColorFunction
                asDynamic().getLineWidth = countryLineWidthFunction
                opacity = 0.7

                asDynamic().lineWidthMinPixels = 1.5

                asDynamic().uniqueIdProperty = "id"
                pickable = false
                autoHighlight = true
                asDynamic().highlightColor = arrayOf(192, 0, 0, 63)

                updateTriggers = jso<dynamic> {
                    getFillColor = arrayOf(countryFillColorFunction, countryVariable, groupVariable, level)
                }

                asDynamic().onClick = { info : dynamic ->
                    console.log("Click on $info")
                    console.log("Click on ${info.`object`.properties}")
                    val countryId = info.`object`.properties.Name.unsafeCast<String>()

                    println("navigateToCountry $countryId")
                    appState.navigateToCountry(navigate, countryId)
                }

//                onHover = { info ->
//                    println("Hover: $info")
//                }
            })

            val palette = paletteFactory.createDefaultQualititativePalette()
            val colormap = colorMapFactory.createCategoricalColorMap(EPR.ethnicNames, false, palette)

            val groupFillColorFunction = useMemo(countryVariable, groupVariable, level) {{ d: dynamic ->
                if(level != "country") {
                    if(groupVariable != "statusid") {
                        val ethnicName = d.properties.Name as String?
                        val color = colormap.getColor(ethnicName)
                        if (color != null) {
                            //                    println("$ethnicName -> ${color.toHTMLColor()}")
                            arrayOf(color.r.toInt(), color.g.toInt(), color.b.toInt(), 127)
                        } else {
                            arrayOf(127, 127, 127, 127)
                        }
                    } else {
                        val properties = d.properties.unsafeCast<JsonObject>()
                        val value = properties.get(groupVariable) as Number?
                        if(value != null) {
                            val color1 = arrayOf(0, 255, 0) // Blue
                            val color2 = arrayOf(255, 0, 0) // Red

                            val color = interpolateColor(value, color1, color2, 1.0, 10.0, 127)
//                        val color = countryColormap.getColor(doubleValue)
                            if(color != null) {
//                            arrayOf(color.getRed().toInt(), color.getGreen().toInt(), color.getBlue().toInt())
                                color
                            } else {
                                arrayOf(255, 140, 0, 0)
                            }
                        } else {
                            arrayOf(255, 140, 0, 0)
                        }
                    }
                } else {
                    arrayOf(255, 140, 0, 0)
                }
            }}

            val groupsLayer = MVTLayer<Any>(jso {
                id = "groups-layer-$year"
                this.data = "/geogroupperiods/$year"

                asDynamic().stroked = false
                asDynamic().filled = true
                asDynamic().getFillColor = groupFillColorFunction
                opacity = 0.7

                asDynamic().uniqueIdProperty = "id"
                pickable = true
                autoHighlight = true
                asDynamic().highlightColor = arrayOf(192, 0, 0, 63)
//                onHover = { info ->
//                    println("Hover: $info")
//                }

                updateTriggers = jso<dynamic> {
                    getFillColor = arrayOf(countryVariable, groupVariable, level)
                }
                asDynamic().onClick = { info : dynamic ->
                    console.log("Group Click on $info")
                    console.log("Group Click on ${info.`object`.properties}")

                    val countryId = info.`object`.properties.CountryName.unsafeCast<String>()
                    val groupId = info.`object`.properties.Name.unsafeCast<String>()

                    println("navigateToGroup $countryId $groupId")

                    appState.navigateToCountry(navigate, countryId)

//                    val viewId = "pfe"
//                    val countryId = info.`object`.properties.Name.unsafeCast<String>()
//                    navigate("${encodeURIComponent(countryId)}")
                }
            })

            layers = arrayOf<Layer>(countriesLayer, groupsLayer)
        }.also {
            println("Created layers in $it for year $year")
        }

        val interpolator = useMemo() { FlyToInterpolator() }

        val tooltip = { info: dynamic ->
            val `object` = info.`object`
            if(`object` != null) {
                val countryId = `object`.properties.CountryName.unsafeCast<String>()
                val groupId = `object`.properties.Name.unsafeCast<String>()
                info.html = "<h2>$groupId in $countryId</h2>"
                info.style = jso<dynamic> {
                    backgroundColor = "#ffffff09"
                    color = "#333359"
                    fontSize = "0.8em"
                    borderRadius = "6px"
                    padding = "0px 10px 10px 10px"
                }
                console.log("Click on ${info.`object`.properties}")
                info
            } else {
                null
            }
        }

        DeckGL {
//            this.viewState = viewState
            this.initialViewState = jso {
                this.latitude = latitude
                this.longitude = longitude
                this.zoom = zoom
                this.pitch = 0
                this.bearing = 0

                this.maxZoom = 16

                this.transitionDuration = 2000
                this.transitionInterpolator = interpolator
            }
            controller = true
            if(!MAPLIBRE) {
                this.layers = layers
            }
            parameters = jso {
//                this.blendFunc = blendFunc
//                this.blendEquation = blendEquation
                this.depthTest = false
            }
//            ContextProvider = _MapContext.Provider

//            this.asDynamic().onViewStateChange = onViewStateChange

            asDynamic().getTooltip = tooltip

            val getTerrain = false
            val dark = true

            val style : dynamic = jso {
                version = 8
                sources = jso {
                    geocountryperiods = jso {
                        url = "/geocountryperiods/$year"
                        type = "vector"
                    }
                    natural_earth = jso {
                        url = "/natural_earth"
                        type = "raster"
                    }
                    natural_earth_2 = jso {
                        url = "/natural_earth_2"
                        type = "raster"
                    }
                    natural_earth_2_shaded_relief = jso {
                        url = "/natural_earth_2_shaded_relief"
                        type = "raster"
                    }
                    natural_earth_cross_blended_hypso = jso {
                        url = "/natural_earth_cross_blended_hypso"
                        type = "raster"
                    }
                    natural_earth_cross_blended_hypso_shaded_relief = jso {
                        url = "/natural_earth_cross_blended_hypso_shaded_relief"
                        type = "raster"
                    }
                    natural_earth_gray_earth_hypso_shaded_relief = jso {
                        url = "/natural_earth_gray_earth_hypso_shaded_relief"
                        type = "raster"
                    }
                    natural_earth_shaded_relief = jso {
                        url = "/natural_earth_shaded_relief"
                        type = "raster"
                    }
                }

                if(!MAPLIBRE) {
                    this.layers = arrayOf<dynamic>(
                        jso {
                            this.id = "background"
                            type = "background"
                            layout = jso {
                                visibility = "visible"
                            }
                            paint = jso<Any> {
                                asDynamic()["background-color"] = "rgba(6, 35, 51, 1)"
                            }
                        },
                        jso<Layer> {
                            asDynamic().id = "natural_earth_gray_earth_hypso_shaded_relief"
                            asDynamic().type = "raster"
                            asDynamic().source = "natural_earth_gray_earth_hypso_shaded_relief"
                            asDynamic().minzoom = 0.0
                            asDynamic().layout = jso {
                                visibility = "visible"
                            }
                        })
                } else {
                    this.layers = arrayOf<dynamic>(
                        jso {
                            this.id = "background"
                            type = "background"
                            layout = jso {
                                visibility = "visible"
                            }
                            paint = jso<Any> {
                                asDynamic()["background-color"] = "rgba(6, 35, 51, 1)"
                            }
                        },
                        jso<Layer> {
                            asDynamic().id = "natural_earth_2"
                            asDynamic().type = "raster"
                            asDynamic().source = "natural_earth_2"
                            asDynamic().minzoom = 0.0
                            asDynamic().layout = jso {
                                visibility = "visible"
                            }
                        },
                        jso<Layer> {
                            asDynamic().id = "countries"
                            asDynamic().type = "line"
                            asDynamic().source = "geocountryperiods"
                            asDynamic()["source-layer"] = "countries"
                            asDynamic().minzoom = 0.0
                            asDynamic().layout = jso {
                                visibility = "visible"
                            }
                            asDynamic().paint = jso<Paint> {
                                asDynamic()["line-blur"] = 1
                                asDynamic()["line-color"] = "rgba(255, 50, 50, 1)"
                                asDynamic()["line-width"] = 5
                            }
                        }
                    )
                }
//                center = arrayOf(8.265, 46.786)
//                this.zoom = 7.64
//                bearing = 0
//                pitch = 0
//                transition = jso {}
            }

            ReactMapGL {
////                this.mapLib = maplibregl
//                this.mapStyle = "/static/growup-dark.json"
//                asDynamic().renderWorldCopies = false
                this.mapStyle = style
//                this.mapStyle = if(dark) {
//                    if(getTerrain) {
//                        "/static/terrain-dark.json"
//                    } else {
//                        "/static/style-dark.json"
//                    }
//                } else {
//                    if(getTerrain) {
//                        "/static/terrain-light.json"
//                    } else {
//                        "/static/style-light.json"
//                    }                }
           }

//            NavigationControl {
//                visualizePitch = true
//            }

        }

        div {
            css {
                position = absolute
                bottom = 0.px
                left = 0.px

                height = 150.px
                width = 100.pct

                paddingRight = 16.px
                paddingLeft = 16.px
            }

            TimeSeriesView {

            }
        }

        div {
            css {
                position = absolute
                top = 0.px
                left = 0.px

//                height = 40.px
//                width = 100.pct

                paddingRight = 16.px
                paddingLeft = 16.px

                display = Display.contents
            }

            LevelView {

            }
        }
    }
}
