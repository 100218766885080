package com.macrofocus.common.properties

import kotlin.properties.ReadOnlyProperty
import kotlin.properties.ReadWriteProperty

object PropertyDelegates {
    fun <T> immutable(property: Property<T>): ReadOnlyProperty<Any?, T> =
        object : KReadOnlyProperty<T>(property) {}

    fun <T> mutable(property: MutableProperty<T>): ReadWriteProperty<Any?, T> =
            object : KReadWriteProperty<T>(property) {}
}
