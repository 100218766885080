package org.mkui.color.crossplatform

actual class CPColor actual constructor(val r: UByte, val g: UByte, val b: UByte, val a: UByte) : IColor {
    val color: String = toJavaScriptColor()

    actual constructor(r: UByte, g: UByte, b: UByte) : this(r, g, b, 255u)
    actual constructor(r: Int, g: Int, b: Int) : this(r.toUByte(), g.toUByte(), b.toUByte(),255u)
    actual constructor(r: Int, g: Int, b: Int, a: Int) : this(r.toUByte(), g.toUByte(), b.toUByte(), a.toUByte())
    actual constructor(r: Float, g: Float, b: Float) : this(
        (r * 255 + 0.5).toInt(),
        (g * 255 + 0.5).toInt(),
        (b * 255 + 0.5).toInt(),
        255)
    actual constructor(r: Float, g: Float, b: Float, a: Float) : this(
        (r * 255 + 0.5).toInt(),
        (g * 255 + 0.5).toInt(),
        (b * 255 + 0.5).toInt(),
        (a * 255 + 0.5).toInt())

    actual constructor(rgba: Int) : this(
        rgba shr 16 and 0xff,
        rgba shr 8 and 0xff,
        rgba shr 0 and 0xff,
        rgba shr 24 and 0xff)

    actual override fun getRed(): UByte {
        return r
    }

    actual override fun getGreen(): UByte {
        return g
    }

    actual override fun getBlue(): UByte {
        return b
    }

    actual override fun getAlpha(): UByte {
        return a
    }

    fun getNative(): String {
        return color
    }
}