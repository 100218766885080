package ch.ethz.icr.growup.pfe.view

import ch.ethz.icr.growup.pfe.context.useAppState
import ch.ethz.icr.growup.pfe.theme.ThemeContext
import ch.ethz.icr.growup.pfe.theme.useSetYear
import ch.ethz.icr.growup.pfe.theme.useYear
import js.objects.jso
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import mui.icons.material.PlayCircleOutlineOutlined
import mui.icons.material.PlayCircleOutlined
import mui.icons.material.StopCircle
import mui.icons.material.StopCircleOutlined
import mui.material.*
import mui.material.Size
import mui.system.sx
import org.mkui.react.toState
import react.*
import web.cssom.*
import web.events.Event

val TimeSeriesView = FC<Props> {
    var theme by useRequiredContext(ThemeContext)
    val (year, setYear) = useAppState().year.toState()
    val (country, _) = useAppState().country.toState()
    val (level, _) = useAppState().level.toState()
    val (systemVariable, _) = useAppState().systemVariable.toState()
    val (countryVariable, _) = useAppState().countryVariable.toState()
    val (groupVariable, _) = useAppState().groupVariable.toState()

    Card {
        CardContent {
            sx {
                position = Position.absolute
                top = 0.px
                left = 0.px
                width = 100.pct
                height = 100.pct

                backgroundColor = if (theme.palette.mode == PaletteMode.light) rgb(255, 255, 255, 0.5) else rgb(0, 0, 0, 0.2)
                backgroundImage = if (theme.palette.mode == PaletteMode.light)
                    linearGradient(
                        stop(rgb(0, 0, 0, 0.08), 0.pct),
                        stop(rgb(0, 0, 0, 0.01), 100.pct)
                    )
                else
                    linearGradient(
                        stop(rgb(255, 255, 255, 0.08), 0.pct),
                        stop(rgb(255, 255, 255, 0.01), 100.pct)
                    )
//                    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
                boxShadow = BoxShadow(0.px, 3.px, 5.px, -1.px, rgb(0, 0, 0, 0.2))
                backdropFilter = blur(4.px)
            }

            Box {
                TimeSeries {
                    this.country = country
                    this.level = level
                    this.systemVariable = systemVariable
                    this.countryVariable = countryVariable
                    this.groupVariable = groupVariable
                }
            }

//            Typography {
//                color = "text.secondary"
//                gutterBottom = true
//                +"Atlas"
//            }
//            Typography {
//                component = div
//                variant = h5
//
//                +"be"
//            }
//        }
//        CardActions {
//            Button {
//                size = Size.small
//                +"Learn More"
//            }
        }
    }

    val (getLocalShiftValue, setLocalShiftValue) = useState(year)

    val (getJob, setJob) = useState<Job?>(null)

    IconButton {
//                        classes(classes.menuButton)
        color = IconButtonColor.inherit
//                    edge = IconButtonEdge.start
        onClick = {
            val job = getJob
            if(job != null) {
                job.cancel()
                setJob(null)
            } else {
                val ms = 500L
                println("Starting animation with 5 minutes = $ms ms")
                setJob(GlobalScope.launch {
                    var current = getLocalShiftValue
                    while (true) {
                        delay(ms)
                        current = if(current < 2022) current + 1 else 1946

//                                println("Next date: " + next.toRawString())
//                                masterDateTime.value = next.toDateTime()
                        println("Shifting to $current")
                        setLocalShiftValue(current)
                        setYear(current)
                    }
                })
            }
        }

        if(getJob != null) StopCircleOutlined {} else PlayCircleOutlineOutlined {}
    }

    Tooltip {
        title = ReactNode("Year")

        val year = useYear()
        val setYear = useSetYear()

        Slider {
            sx = jso {
                width = 93.pct
//                marginRight = 10.px
                position = Position.absolute
//                bottom = -22.px
                bottom = 16.px
//                height = 5.px
                zIndex = integer(3_000)
            }

            value = year

            step = 1
            marks
            min = 1946
            max = 2023
            size = Size.medium
            valueLabelDisplay = "auto"

            onChange = { event: Event, value: dynamic, activeThumb: Number ->
                println("onChange: $value")
                if (value is Number) {
//                    event.stopImmediatePropagation()
//                    event.preventDefault()
                    setYear(value.toInt())
                }
            }
//            onDrag = { event: MouseEvent<HTMLSpanElement, *> ->
//                event.stopPropagation()
//                event.preventDefault()
//            }
            onChangeCommitted = { event: react.dom.events.SyntheticEvent<*, *>, value: dynamic ->
                println("onChangeCommitted: $value")
                if (value is Number) {
//                    event.stopPropagation()
//                    event.preventDefault()
                    setYear(value.toInt())
                }
            }
        }
    }
}
