package org.molap.dataframe

/**
 * An event that characterizes a change in a data frame.
 *
 * @param <R> the type of row keys
 * @param <C> the type of column keys
</C></R> */
class DataFrameEvent<R, C>
/**
 *
 * @param affectedRows the affected rows
 * @param affectedColumns the affected columns
 * @param structureChanged true if the rows or columns of the data frame have changed; false if only the data have change.
 */(
    /**
     * Returns the rows that have been affected by the change.
     *
     * @return the modified rows
     */
    val affectedRows: Iterable<R>?,
    /**
     * Rreturns the column that have been affected by teh change.
     *
     * @return the modified columns
     */
    val affectedColumns: Iterable<C>?,
    val isStructureChanged: Boolean
)