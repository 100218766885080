package ch.ethz.icr.growup.pfe.view

import react.FC
import react.Props
import react.router.useLoaderData

val ViewMaterial = FC<Props> {
    val ViewComponent = useLoaderData()
        .unsafeCast<View>()
        .Component

    ViewComponent()
}