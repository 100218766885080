package ch.ethz.icr.growup.pfe.context

import js.objects.jso
import react.FC
import react.PropsWithChildren

val AppStateModule = FC<PropsWithChildren> { props ->
    val INITIAL_VIEW_STATE: dynamic = jso {
        latitude = 47.021278030856564
        longitude = 7.969664962869274
        zoom = 1.0
        pitch = 0
        bearing = 0

        maxZoom = 16
//        onLoad = onLoadCallback
    }

    val appState = AppState()

    AppStateContext(appState) {
        +props.children
    }
}