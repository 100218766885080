package ch.ethz.icr.growup.pfe.page

import emotion.react.css
import mui.material.TooltipPlacement
import mui.material.styles.Theme
import mui.system.SxProps
import react.FC
import react.PropsWithChildren
import react.ReactNode
import react.dom.html.ReactHTML
import web.cssom.MarginLeft

external interface LogoProps : PropsWithChildren,mui.system.PropsWithSx {
    var version: String
    var logo: String
    var height: Double?
    var marginLeft: MarginLeft?
    override var sx: SxProps<Theme>?
}

val Logo = FC<LogoProps> { props ->
    mui.material.Tooltip {
        title = ReactNode("Version ${props.version}")
        placement = TooltipPlacement.bottomEnd

        ReactHTML.img {
            if(props.marginLeft != null) {
                css {
                    marginLeft = props.marginLeft
                }
            }
            src = props.logo
            height = if(props.height != null) props.height else 36.0
        }
    }
}