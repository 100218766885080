package com.macrofocus.common.json

/**
 * Client-side implementation of JsonObject interface.
 */
actual external interface JsonObject : JsonValue {
}

actual operator fun <T : JsonValue?> JsonObject.get(key: String): T {
//    return js("var value = this[key]; value == null ? value : Object(value);")
    return this.unsafeCast<dynamic>()[key]
}

actual fun JsonObject.getArray(key: String): JsonArray? {
    return get<JsonValue>(key).asJsonArray()
}

actual fun JsonObject.getBoolean(key: String): Boolean {
    return get<JsonValue>(key).asBoolean()
}

actual fun JsonObject.getNumber(key: String): Double {
    return get<JsonValue>(key).asNumber()
}

actual fun JsonObject.getObject(key: String): JsonObject? {
    return get<JsonValue>(key).asJsonObject()
}

actual fun JsonObject.getString(key: String): String? {
    return get<JsonValue>(key).asString()
}

actual fun JsonObject.hasKey(key: String): Boolean {
//    return js("key in this;")
    return this.unsafeCast<dynamic>().hasOwnProperty(key)
}

actual fun JsonObject.keys(): Array<String> {
//    val str: Array<String> = keys0()
//    return reinterpretCast(str)
    return js("Object").keys(this)
}

actual fun JsonObject.put(key: String, value: JsonValue?) {
//    js("this[key] = value;")
    this.unsafeCast<dynamic>()[key] = value
}

actual fun JsonObject.put(key: String, value: String) {
    put(key, JsonFactory.create(value))
}

actual fun JsonObject.put(key: String, value: Double) {
    put(key, JsonFactory.create(value))
}

actual fun JsonObject.put(key: String, bool: Boolean) {
    put(key, JsonFactory.create(bool))
}

@Deprecated("use {@link #put(String, elemental.json.JsonValue)} instead.")
fun JsonObject.put0(key: String, value: JsonValue?) {
    put(key, value)
}

actual fun JsonObject.remove(key: String?) {
    js("delete this[key];")
}

//    companion object {
//        fun create(): JsonObject {
//            return js("{}")
//        }
//    }
//}