package ch.ethz.icr.growup.pfe.view

import ch.ethz.icr.growup.pfe.context.useAppState
import ch.ethz.icr.growup.pfe.theme.ThemeContext
import js.objects.jso
import mui.material.*
import mui.material.Size
import mui.system.sx
import org.mkui.react.toState
import react.*
import react.dom.events.MouseEvent
import web.cssom.*
import web.html.HTMLElement

val LevelView = FC<Props> {
    var theme by useRequiredContext(ThemeContext)
    val (country, _) = useAppState().country.toState()
    val (level, setLevel) = useAppState().level.toState()
    val (systemVariable, setSystemVariable) = useAppState().systemVariable.toState()
    val (countryVariable, setCountryVariable) = useAppState().countryVariable.toState()
    val (groupVariable, setGroupVariable) = useAppState().groupVariable.toState()

    Card {
        CardContent {
            sx {
                position = Position.absolute
                top = 0.px
                left = 0.px
//                width = 100.pct
//                height = 100.pct

                backgroundColor = if (theme.palette.mode == PaletteMode.light) rgb(255, 255, 255, 0.5) else rgb(0, 0, 0, 0.2)
                backgroundImage = if (theme.palette.mode == PaletteMode.light)
                    linearGradient(
                        stop(rgb(0, 0, 0, 0.08), 0.pct),
                        stop(rgb(0, 0, 0, 0.01), 100.pct)
                    )
                else
                    linearGradient(
                        stop(rgb(255, 255, 255, 0.08), 0.pct),
                        stop(rgb(255, 255, 255, 0.01), 100.pct)
                    )
//                    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
                boxShadow = BoxShadow(0.px, 3.px, 5.px, -1.px, rgb(0, 0, 0, 0.2))
                backdropFilter = blur(4.px)
            }

            ToggleButtonGroup {
                orientation = Orientation.horizontal
//                size = Size.small

                ToggleButton {
                    disabled = false
                    size = Size.small
                    sx = jso {
                        textTransform = None.none
//                        fontSize = FontSize.xxSmall
//                        padding = 1.px
//                        marginTop = -3.px
                    }

                    selected = level == "system"
                    onClick = { event: MouseEvent<HTMLElement, *>, value: dynamic ->
                        setLevel("system")
                    }
                    +"System"
                }

                ToggleButton {
                    disabled = false
//                    size = Size.small
                    sx = jso {
                        textTransform = None.none
//                        fontSize = FontSize.xxSmall
//                        padding = 1.px
//                        marginTop = -3.px
                    }

                    selected = level == "country"
                    onClick = { event: MouseEvent<HTMLElement, *>, value: dynamic ->
                        setLevel("country")
                    }
                    +"Country"
                }

                ToggleButton {
                    disabled = false
//                    size = Size.small
                    sx = jso {
                        textTransform = None.none
//                        fontSize = FontSize.xxSmall
//                        padding = 1.px
//                        marginTop = -3.px
                    }

                    selected = level == "group"
                    onClick = { event: MouseEvent<HTMLElement, *>, value: dynamic ->
                        setLevel("group")
                    }
                    +"Ethnic group"
                }
            }
            val systemVariables = listOf(
                "egip_groups_count",
                "excl_groups_count",
                "regaut_groups_count",
                "regaut_excl_groups_count",
                "regaut_egip_groups_count",
                "rlvt_groups_count",
                "actv_groups_count",
                "lpop",
                "egippop",
                "legippop",
                "exclpop",
                "lexclpop",
                "discrimpop",
                "ldiscrimpop",
                "maxexclpop",
                "lmaxexclpop",
                "ethnic_sn",
                "ethnic_np",
                "regautpop",
                "regautexclpop",
                "regautegippop",
                "maxregautexclpop",
                "maxregautegippop",
                "downgradedpop",
                "downgradedexclpop",
                "downgradedregautpop",
                "maxdowngradedpop",
                "maxdowngradedexclpop",
                "maxdowngradedregautpop",
                "cntr_relevance",
                "nstar",
                "onset_ko_flag",
                "onset_ko_terr_flag",
                "onset_ko_gov_flag",
                "onset_ko_eth_flag",
                "onset_ko_noneth_flag",
                "onset_ko_terr_eth_flag",
                "onset_ko_gov_eth_flag",
                "onset_ko_terr_noneth_flag",
                "onset_ko_gov_noneth_flag",
                "incidence_flag",
                "incidence_terr_flag",
                "incidence_gov_flag",
                "incidence_eth_flag",
                "incidence_noneth_flag",
                "incidence_terr_eth_flag",
                "incidence_gov_eth_flag",
                "incidence_terr_noneth_flag",
                "incidence_gov_noneth_flag",
                "onset_do_flag",
                "onset_do_terr_flag",
                "onset_do_gov_flag",
                "onset_do_eth_flag",
                "onset_do_noneth_flag",
                "onset_do_terr_eth_flag",
                "onset_do_gov_eth_flag",
                "onset_do_terr_noneth_flag",
                "onset_do_gov_noneth_flag",
                "warhist",
                "warhist_terr",
                "warhist_gov",
                "peaceyears",
                "peaceyears_terr",
                "peaceyears_gov",
                "area_sqkm",
                "oil_acor_count",
                "oil_giant_fields_count",
                "oil_petrodata_count",
                "nightlight_total",
                "nightlight92_total",
                "nightlight15_total",
                "elevsd",
                "meanelev",
                "pop90_total",
                "pop00_total",
                "pop10_total",
                "gdp90_total",
                "gdp95_total",
                "gdp00_total",
                "gdp05_total",
            )
            val countryVariables = listOf(
                "egip_groups_count",
                "excl_groups_count",
                "regaut_groups_count",
                "regaut_excl_groups_count",
                "regaut_egip_groups_count",
                "rlvt_groups_count",
                "actv_groups_count",
                "lpop",
                "egippop",
                "legippop",
                "exclpop",
                "lexclpop",
                "discrimpop",
                "ldiscrimpop",
                "maxexclpop",
                "lmaxexclpop",
                "ethnic_sn",
                "ethnic_np",
                "regautpop",
                "regautexclpop",
                "regautegippop",
                "maxregautexclpop",
                "maxregautegippop",
                "downgradedpop",
                "downgradedexclpop",
                "downgradedregautpop",
                "maxdowngradedpop",
                "maxdowngradedexclpop",
                "maxdowngradedregautpop",
                "cntr_relevance",
                "nstar",
                "onset_ko_flag",
                "onset_ko_terr_flag",
                "onset_ko_gov_flag",
                "onset_ko_eth_flag",
                "onset_ko_noneth_flag",
                "onset_ko_terr_eth_flag",
                "onset_ko_gov_eth_flag",
                "onset_ko_terr_noneth_flag",
                "onset_ko_gov_noneth_flag",
                "incidence_flag",
                "incidence_terr_flag",
                "incidence_gov_flag",
                "incidence_eth_flag",
                "incidence_noneth_flag",
                "incidence_terr_eth_flag",
                "incidence_gov_eth_flag",
                "incidence_terr_noneth_flag",
                "incidence_gov_noneth_flag",
                "onset_do_flag",
                "onset_do_terr_flag",
                "onset_do_gov_flag",
                "onset_do_eth_flag",
                "onset_do_noneth_flag",
                "onset_do_terr_eth_flag",
                "onset_do_gov_eth_flag",
                "onset_do_terr_noneth_flag",
                "onset_do_gov_noneth_flag",
                "warhist",
                "warhist_terr",
                "warhist_gov",
                "peaceyears",
                "peaceyears_terr",
                "peaceyears_gov",
                "area_sqkm",
                "oil_acor_count",
                "oil_giant_fields_count",
                "oil_petrodata_count",
                "nightlight_total",
                "nightlight92_total",
                "nightlight15_total",
                "elevsd",
                "meanelev",
                "pop90_total",
                "pop00_total",
                "pop10_total",
                "gdp90_total",
                "gdp95_total",
                "gdp00_total",
                "gdp05_total",
                )
            val groupVariables = listOf(
                "groupname",
                "statusid",
            )

            if(level == "system") {
                Box {
                    sx {
                        //                    position = Position.absolute
                        //                    bottom = 0.px
                        minWidth = 120.px
                    }
                    FormControl {
                        fullWidth = false
                        InputLabel {
                            id = "demo-simple-select-label"
                            +"Variable"
                        }
                        Select {
                            labelId = "demo-simple-select-label"
                            id = "demo-simple-select"
                            value = systemVariable
                            label = ReactNode("Variable")
                            onChange = { event, _ ->
                                setSystemVariable(event.target.value)
                            }

                            for (variable1 in systemVariables) {
                                MenuItem {
                                    value = variable1
                                    +variable1
                                }
                            }
                        }
                    }
                }
            } else if (level == "country") {
                Box {
                    sx {
//                    position = Position.absolute
//                    bottom = 0.px
                        minWidth = 120.px
                    }
                    FormControl {
                        fullWidth = false
                        InputLabel {
                            id = "demo-simple-select-label"
                            +"Variable"
                        }
                        Select {
                            labelId = "demo-simple-select-label"
                            id = "demo-simple-select"
                            value = countryVariable
                            label = ReactNode("Variable")
                            onChange = { event, _ ->
                                setCountryVariable(event.target.value)
                            }

                            for (variable1 in countryVariables) {
                                MenuItem {
                                    value = variable1
                                    +variable1
                                }
                            }
                        }
                    }
                }

            } else {
                Box {
                    sx {
//                    position = Position.absolute
//                    bottom = 0.px
                        minWidth = 120.px
                    }
                    FormControl {
                        fullWidth = false
                        InputLabel {
                            id = "demo-simple-select-label"
                            +"Variable"
                        }
                        Select {
                            labelId = "demo-simple-select-label"
                            id = "demo-simple-select"
                            value = groupVariable
                            label = ReactNode("Variable")
                            onChange = { event, _ ->
                                setGroupVariable(event.target.value)
                            }

                            for (variable1 in groupVariables) {
                                MenuItem {
                                    value = variable1
                                    +variable1
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
