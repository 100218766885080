package com.macrofocus.common.index

abstract class AbstractIndex<K> : Index<K> {
    override fun keysArray(): Array<Any?>? {
        val list: ArrayList<K> = ArrayList<K>(size)
        for (k in keys()) {
            list.add(k)
        }
        return list.toTypedArray()
    }


//    override fun toString(): String {
//        var keys: String? = null
//        for (k in keys()!!) {
//            if (keys != null) {
//                keys += ","
//            } else {
//                keys = ""
//            }
//            keys += k?.toString() ?: "(null)"
//        }
//        return.getSimpleName() + "{" + keys + "}"
//    }
}
