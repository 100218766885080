package ch.ethz.icr.growup.pfe.view

import ch.ethz.icr.growup.Variable
import emotion.react.css
import js.objects.jso
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromDynamic
import mui.material.*
import mui.system.sx
import org.w3c.fetch.RequestInit
import react.*
import react.dom.aria.ariaDescribedBy
import react.dom.aria.ariaLabelledBy
import react.dom.events.ChangeEvent
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import web.cssom.Auto
import web.cssom.Display
import web.cssom.None
import web.html.HTMLInputElement
import web.html.InputType
import web.window.WindowTarget

val DownloadView = FC<Props> {
    var variables by useState<List<Variable>>()

    useEffectOnce {
        GlobalScope.launch {
            variables = getVariables()
        }
    }

    val (aggregationOptionGroup, setAggregationOptionGroup) = useState("group");

    val handleAggregationOptionGroupChange = { event: ChangeEvent<HTMLInputElement>, value: String ->
        setAggregationOptionGroup(value);
    }

    // Set up group-level variable selection panel
    var groupPoliticalCheckBox by useState(false)
    var groupConflictCheckBox by useState(false)
    var groupHierarchyCheckBox by useState(false)
    var groupGeographyCheckBox by useState(false)
    var groupGridCheckBox by useState(false)
    var groupTekCheckBox by useState(false)
    var groupCleavageCheckBox by useState(false)
    var groupPrioStaticCheckBox by useState(false)
    var groupPrioYearlyCheckBox by useState(false)

    // Set up country-level variable selection panel
    var countryPoliticalCheckBox by useState(false)
    var countryConflictCheckBox by useState(false)
    var countryGeographyCheckBox by useState(false)
    var countryGridCheckBox by useState(false)

    println("$groupPoliticalCheckBox $groupConflictCheckBox")

    val varCollectionList: List<String> = useMemo(
        aggregationOptionGroup,
        groupPoliticalCheckBox,
        groupConflictCheckBox,
        groupHierarchyCheckBox,
        groupGeographyCheckBox,
        groupGridCheckBox,
        groupTekCheckBox,
        groupCleavageCheckBox,
        groupPrioStaticCheckBox,
        groupPrioYearlyCheckBox,
        countryConflictCheckBox,
        countryPoliticalCheckBox,
        countryGeographyCheckBox,
        countryGridCheckBox
    ) {
        val list = arrayListOf<String>()

        // Value change listeners for group-level variable selection
        if (aggregationOptionGroup == "group") {
            list += "groupdefault"
            if (groupPoliticalCheckBox) list += "grouppolitical"
            if (groupConflictCheckBox) list += "groupconflict"
            if (groupHierarchyCheckBox) list += "grouphierarchy"
            if (groupGeographyCheckBox) list += "groupgeography"
            if (groupGridCheckBox) list += "groupgrid"
            if (groupTekCheckBox) list += "grouptek"
            if (groupCleavageCheckBox) list += "groupic"
            if (groupPrioStaticCheckBox) list += "pgridstatic"
            if (groupPrioYearlyCheckBox) list += "pgridyearly"
        }

        // Value change listeners for country-level variable selection
        if (aggregationOptionGroup == "country") {
            list += "countrydefault"
            if (countryConflictCheckBox) list += "countryconflict"
            if (countryPoliticalCheckBox) list += "countrypolitical"
            if (countryGeographyCheckBox) list += "countrygeography"
            if (countryGridCheckBox) list += "countrygrid"
        }

        list
    }

    val varnames: String? = useMemo(variables, varCollectionList) {
        val list = variables?.filter { it.publish == 1 && varCollectionList.contains(it.category) }
            ?.joinToString(",") { it.varname }
        list
    }

    // Define state for username, password, and authentication status
    val (username, setUsername) = useState("")
    val (password, setPassword) = useState("")
    val (isLoggedIn, setLoggedIn) = useState(false)

    // Handle login form submission
    fun handleLogin() {
        window.fetch("/login", RequestInit(method = "POST", headers = jso {
            ContentType = "application/json"
        }, body = JSON.stringify(mapOf("username" to username, "password" to password))))
            .then { response ->
                if (response.ok) {
                    setLoggedIn(true)
                } else {
                    // Handle login failure
                }
            }
    }

//    div {
//        css {
//            position = Position.relative
//            height = 100.vh // Fill the height of the viewport
//            display = Display.flex
//            flexDirection = FlexDirection.column
//            justifyContent = JustifyContent.center
//            alignItems = AlignItems.center
////            padding = theme.spacing(2)
//            boxSizing = BoxSizing.borderBox
//            overflowY = Auto.auto // Enable vertical scrolling
//        }

        Card {
            css {
//                position = Position.relative
//                width = 100.pct // Card fills the width of its container
//                display = Display.flex
//                flexDirection = FlexDirection.column
//                justifyContent = JustifyContent.spaceBetween // Align content and actions
//                minHeight = 0.px // Reset min-height
//                height = 100.vh
                overflowY = Auto.auto // Enable vertical scrolling
            }

            CardContent {
//                css {
//                    flexGrow = number(1.0)
//                    overflowY = Auto.auto // Enable vertical scrolling
//                }

                FormGroup {
                    FormLabel {
                        id = "aggregationOptionGroupLabel"
                        ariaDescribedBy = "aggregationOptionGroupHelper"
                        +"Level of Aggregation"
                    }
                    FormHelperText {
                        id = "aggregationOptionGroupHelper"
                        +"Please choose whether you would like to download group-level or country-level data:"
                    }
                    RadioGroup {
                        row = true
                        ariaLabelledBy = "aggregationOptionGroupLabel"
                        name = "aggregationOptionGroup"
                        value = aggregationOptionGroup
                        onChange = handleAggregationOptionGroupChange
                        FormControlLabel {
                            value = "group"
                            control = Radio.create()
                            label = ReactNode("Group-Level Data")
                        }
                        FormControlLabel {
                            value = "country"
                            control = Radio.create()
                            label = ReactNode("Country-Level Data")
                        }
                    }

                    // Set up group-level variable selection panel
                    FormGroup {
                        sx {
                            display = if (aggregationOptionGroup == "group") Display.flex else None.none
                        }

                        FormControlLabel {
                            value = groupPoliticalCheckBox
                            onChange = { _, value -> groupPoliticalCheckBox = value }
                            control = Checkbox.create()
                            label = ReactNode("Power Access Data")
                        }
                        FormControlLabel {
                            value = groupConflictCheckBox
                            onChange = { _, value -> groupConflictCheckBox = value }
                            control = Checkbox.create()
                            label = ReactNode("Conflict Data (UCDP ACD, ACD2EPR)")
                        }
                        FormControlLabel {
                            value = groupHierarchyCheckBox
                            onChange = { _, value -> groupHierarchyCheckBox = value }
                            control = Checkbox.create()
                            label = ReactNode("Group Hierarchy Data")
                        }
                        FormControlLabel {
                            value = groupGeographyCheckBox
                            onChange = { _, value -> groupGeographyCheckBox = value }
                            control = Checkbox.create()
                            label = ReactNode("Settlement Area Data (GeoEPR variables)")
                        }
                        FormControlLabel {
                            value = groupGridCheckBox
                            onChange = { _, value -> groupGridCheckBox = value }
                            control = Checkbox.create()
                            label =
                                ReactNode("Raster Aggregated Data (GRUMPv1 Population, DMSP Stable Nightlights, G-ECON GCP, GTOPO30 Elevation)")
                        }
                        FormControlLabel {
                            value = groupTekCheckBox
                            onChange = { _, value -> groupTekCheckBox = value }
                            control = Checkbox.create()
                            label = ReactNode("Transnational Ethnic Kin (TEK) Data")
                        }
                        FormControlLabel {
                            value = groupCleavageCheckBox
                            onChange = { _, value -> groupCleavageCheckBox = value }
                            control = Checkbox.create()
                            label = ReactNode("Ethnic Dimensions Data")
                        }
                        FormControlLabel {
                            value = groupPrioStaticCheckBox
                            onChange = { _, value -> groupPrioStaticCheckBox = value }
                            control = Checkbox.create()
                            label = ReactNode("Static PRIO-GRID Aggregates")
                        }
                        FormControlLabel {
                            value = groupPrioYearlyCheckBox
                            onChange = { _, value -> groupPrioYearlyCheckBox = value }
                            control = Checkbox.create()
                            label = ReactNode("Yearly PRIO-GRID Aggregates")
                        }
                    }

                    // Set up country-level variable selection panel
                    FormGroup {
                        sx {
                            display = if (aggregationOptionGroup == "country") Display.flex else None.none
                        }

                        FormControlLabel {
                            value = countryPoliticalCheckBox
                            onChange = { _, value -> countryPoliticalCheckBox = value }
                            control = Checkbox.create()
                            label = ReactNode("Power Access Data")
                        }
                        FormControlLabel {
                            value = countryConflictCheckBox
                            onChange = { _, value -> countryConflictCheckBox = value }
                            control = Checkbox.create()
                            label = ReactNode("Conflict Data (UCDP ACD, ACD2EPR)")
                        }
                        FormControlLabel {
                            value = countryGeographyCheckBox
                            onChange = { _, value -> countryGeographyCheckBox = value }
                            control = Checkbox.create()
                            label = ReactNode("Settlement Area Data (CShapes variables)")
                        }
                        FormControlLabel {
                            value = countryGridCheckBox
                            onChange = { _, value -> countryGridCheckBox = value }
                            control = Checkbox.create()
                            label =
                                ReactNode("Raster Aggregated Data (GRUMPv1 Population, DMSP Stable Nightlights, G-ECON GCP, GTOPO30 Elevation)")
                        }
                    }
                }

                TableContainer {
                    component = Paper
                    Table {
                        size = Size.small

                        TableHead {
                            TableCell {
                                +"Variable Name"
                            }
                            TableCell {
                                +"Short Description"
                            }
                        }

                        if (variables != null) {
                            TableBody {
                                for (row in variables!!) {
                                    if (row.publish == 1 && varCollectionList.contains(row.category)) {
                                        TableRow {
                                            TableCell {
                                                +row.varname
                                            }
                                            TableCell {
                                                +row.shortdesc
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            CardActions {
                css {
//                    position = Position.absolute
//                    bottom = 0.px
//                    width = 100.pct
                }

                // Render login form
                if (!isLoggedIn) {
                    div {
                        input {
                            type = InputType.text
                            placeholder = "Username"
                            value = username
                            onChange = { event -> setUsername(event.target.asDynamic().value as String) }
                        }
                        input {
                            type = InputType.password
                            placeholder = "Password"
                            value = password
                            onChange = { event -> setPassword(event.target.asDynamic().value as String) }
                        }
                        button {
                            onClick = { handleLogin() }
                            +"Login"
                        }
                    }
                } else {
                    // Render authenticated content
                }

                Button {
                    size = Size.small
                    +"Login/Register"
                }
                Button {
                    size = Size.small
                    a {
                        href = "/growup.zip?format=xls&aggLevel=$aggregationOptionGroup&displayVariables=$varnames&varCollection=$varnames"
                        target = WindowTarget._blank
                        download
                        +"Download XLS"
                    }
                }
                Button {
                    size = Size.small
                    a {
                        href = "/growup.zip?format=csv&aggLevel=$aggregationOptionGroup&displayVariables=$varnames&varCollection=$varnames"
                        target = WindowTarget._blank
                        download
                        +"Download CSV"
                    }
                }
            }
        }
    }

//    Card {
//        CardContent {
//            Typography {
//                color = "text.secondary"
//                gutterBottom = true
//                +"Download"
//            }
//            Typography {
//                component = div
//                variant = h5
//
//                +"be"
//            }
//        }
//    }
//}

suspend fun getVariables(): List<Variable> {
    val response = window.fetch("/variables", RequestInit(headers = jso {
        Accept = "application/json"
    }))
        .await()
        .json()
        .await()
    return Json.decodeFromDynamic(response)
}
