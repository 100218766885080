package org.mkui.labeling

import com.macrofocus.common.properties.MutableProperties
import com.macrofocus.common.properties.MutableProperty
import com.macrofocus.common.properties.Properties
import com.macrofocus.common.properties.SimpleProperties
import org.mkui.color.*
import org.mkui.color.crossplatform.HSBtoRGB
import org.mkui.color.crossplatform.RGBtoHSB
import org.mkui.font.CPFontFactory
import org.mkui.font.MkFont
import kotlin.js.JsExport
import kotlin.js.JsName
import kotlin.math.abs
import kotlin.math.max

/**
 * Enhanced version of JLabel supporting text effects and word-wrap.
 */
@JsExport
open class EnhancedLabel() {
    var isOpaque = false

    enum class Rendering {
        Truncate,
        Clip,
        WordWrap
    }

    sealed class Effect {
        object Plain : Effect()
        object Shadow : Effect()
        object Glow : Effect()
        object Outline : Effect()
        object Emphasize : Effect()
    }

    var properties: MutableProperties<String?> = SimpleProperties()
    private val rendering = properties.createProperty<Rendering>("rendering", Rendering.Clip)
    private val justified = properties.createProperty("justified", false)
    private val effect = properties.createProperty<Effect>("effect", Effect.Plain)
    private val effectOpacity = properties.createProperty("effectOpacity", 110f / 255f)
    private val minimumCharactersToDisplay = properties.createProperty<Int?>("minimumCharactersToDisplay", null)
    private val angle = properties.createProperty("angle", 0.0)
    private val desiredWidth = properties.createProperty("desiredWidth", Int.MAX_VALUE)
    private val desiredHeight = properties.createProperty("desiredHeight", Int.MAX_VALUE)
    private val text = properties.createProperty<String?>("text", null)
    private val html = properties.createProperty("html", false)
    private val font: MutableProperty<MkFont?> = properties.createProperty("font", CPFontFactory.instance.createDefaultFont())
    private val verticalAlignment = properties.createProperty("verticalAlignment", CENTER)
    private val horizontalAlignment = properties.createProperty("horizontalAlignment", LEADING)
    private val width = properties.createProperty<Int?>("width", null)
    private val height = properties.createProperty<Int?>("height", null)
    private val insetTop = properties.createProperty("insetTop", 0)
    private val insetLeft = properties.createProperty("insetLeft", 0)
    private val insetBottom = properties.createProperty("insetBottom", 0)
    private val insetRight = properties.createProperty("insetRight", 0)
    private val background: MutableProperty<MkColor?> = properties.createProperty("background", null)
    private val foreground: MutableProperty<MkColor?> = properties.createProperty("foreground", null)
    private val enabled = properties.createProperty("enabled", true)
    private val name = properties.createProperty<String?>("name", null)

    @JsName("fromString")
    constructor(text: String?) : this() {
        setText(text)
    }

    open fun setName(name: String?) {
        this.name.value = name
    }

    open fun getName(): String? {
        return name.value
    }

    fun getProperties(): Properties<String?> {
        return properties
    }

    fun getText(): String? {
        return text.value
    }

    fun setText(text: String?) {
        this.text.value = text
    }

    var isHTML: Boolean
        get() = html.value
        set(html) {
            this.html.value = html
        }

    fun getFont(): MkFont? {
        return font.value
    }

    fun setFont(font: MkFont) {
        this.font.value = font
    }

    fun getVerticalAlignment(): Int {
        return verticalAlignment.value
    }

    fun setVerticalAlignment(verticalAlignment: Int) {
        this.verticalAlignment.value = verticalAlignment
    }

    fun getHorizontalAlignment(): Int {
        return horizontalAlignment.value
    }

    fun setHorizontalAlignment(horizontalAlignment: Int) {
        this.horizontalAlignment.value = horizontalAlignment
    }

    fun getHeight(): Int? {
        return height.value
    }

    fun getWidth(): Int? {
        return width.value
    }

    fun getBackground(): MkColor? {
        return background.value
    }

    fun setBackground(background: MkColor?) {
        this.background.value = background
    }

    fun getForeground(): MkColor? {
        return foreground.value
    }

    fun setForeground(foreground: MkColor) {
        this.foreground.value = foreground
    }

    fun isEnabled(): Boolean {
        return enabled.value
    }

    fun getInsetTop(): Int {
        return insetTop.value
    }

    fun getInsetLeft(): Int {
        return insetLeft.value
    }

    fun getInsetBottom(): Int {
        return insetBottom.value
    }

    fun getInsetRight(): Int {
        return insetRight.value
    }

    fun setInsetTop(value: Int) {
        insetTop.value = value
    }

    fun setInsetLeft(value: Int) {
        insetLeft.value = value
    }

    fun setInsetBottom(value: Int) {
        insetBottom.value = value
    }

    fun setInsetRight(value: Int) {
        insetRight.value = value
    }

    fun setInsets(top: Int, left: Int, bottom: Int, right: Int) {
        insetTop.value = top
        insetLeft.value = left
        insetBottom.value = bottom
        insetRight.value = right
    }

    fun getEffect() : Effect {
        return this.effect.value
    }

    fun setEffect(effect: Effect) {
        this.effect.value = effect
    }

    fun getEffectOpacity(): Float {
        return effectOpacity.value
    }

    fun setEffectOpacity(effectOpacity: Float) {
        this.effectOpacity.value = effectOpacity
    }

    fun getRendering(): Rendering {
        return rendering.value
    }

    fun setRendering(rendering: Rendering) {
        this.rendering.value = rendering
    }

    fun getMinimumCharactersToDisplay(): Int? {
        return minimumCharactersToDisplay.value
    }

    fun setMinimumCharactersToDisplay(minimumCharactersToDisplay: Int?) {
        this.minimumCharactersToDisplay.value = minimumCharactersToDisplay
    }

    fun isJustified(): Boolean {
        return justified.value
    }

    fun setJustified(justified: Boolean) {
        this.justified.value = justified
    }

    fun getAngle(): Double {
        return angle.value
    }

    /**
     * Sets the angle in degrees at which the text will be painted
     *
     * @param angle the angle in degrees
     */
    fun setAngle(angle: Double) {
        this.angle.value = angle
    }

    fun getDesiredWidth(): Int {
        return desiredWidth.value
    }

    fun setDesiredWidth(desiredSize: Int) {
        desiredWidth.value = desiredSize
    }

    fun getDesiredHeight(): Int {
        return desiredHeight.value
    }

    fun setDesiredHeight(desiredSize: Int) {
        desiredHeight.value = desiredSize
    }

    companion object {
        /**
         * The central position in an area. Used for
         * both compass-direction constants (NORTH, etc.)
         * and box-orientation constants (TOP, etc.).
         */
        const val CENTER = 0
        //
        // Box-orientation constant used to specify locations in a box.
        //
        /**
         * Box-orientation constant used to specify the top of a box.
         */
        const val TOP = 1

        /**
         * Box-orientation constant used to specify the left side of a box.
         */
        const val LEFT = 2

        /**
         * Box-orientation constant used to specify the bottom of a box.
         */
        const val BOTTOM = 3

        /**
         * Box-orientation constant used to specify the right side of a box.
         */
        const val RIGHT = 4
        //
        // These constants specify a horizontal or
        // vertical orientation. For example, they are
        // used by scrollbars and sliders.
        //
        /** Horizontal orientation. Used for scrollbars and sliders.  */
        const val HORIZONTAL = 0

        /** Vertical orientation. Used for scrollbars and sliders.  */
        const val VERTICAL = 1
        //
        // Constants for orientation support, since some languages are
        // left-to-right oriented and some are right-to-left oriented.
        // This orientation is currently used by buttons and labels.
        //
        /**
         * Identifies the leading edge of text for use with left-to-right
         * and right-to-left languages. Used by buttons and labels.
         */
        const val LEADING = 10

        /**
         * Identifies the trailing edge of text for use with left-to-right
         * and right-to-left languages. Used by buttons and labels.
         */
        const val TRAILING = 11

        /**
         * Identifies the next direction in a sequence.
         *
         * @since 1.4
         */
        const val NEXT = 12
    }

    init {
        setVerticalAlignment(TOP)
    }
}

fun MkColor.replaceBrightness(min: Double): MkColor {
    val hsb: FloatArray = RGBtoHSB(getURed().toInt(), getUGreen().toInt(), getUBlue().toInt(), null)
    return getHSBColor(hsb[0], hsb[1], min.toFloat())
}

fun getHSBColor(h: Float, s: Float, b: Float): MkColor {
    return colorOf(HSBtoRGB(h, s, b))
}

fun MkColor.brightness(): Double {
    val hsb: FloatArray = RGBtoHSB(getURed().toInt(), getUGreen().toInt(), getUBlue().toInt(), null)
    return hsb[2].toDouble()
}

/**
 * Return the monochrome luminance of this color.
 *
 * @return the monochrome luminance
 */
fun MkColor.lum(): Double {
    val r = getURed().toInt()
    val g = getUGreen().toInt()
    val b = getUBlue().toInt()
    return .299 * r + .587 * g + .114 * b
}

/**
 * Return the monochrome luminance of given color
 */
fun MkColor.luminance(): Float {
    val r = getURed().toInt()
    val g = getUGreen().toInt()
    val b = getUBlue().toInt()
    return (.299f * r + .587f * g + .114f * b) / 255f
}

fun MkColor.diff(background: MkColor): Float {
    val r: Int = abs(getURed().toInt() - background.getURed().toInt())
    val g: Int = abs(getUGreen().toInt() - background.getUGreen().toInt())
    val b: Int = abs(getUBlue().toInt() - background.getUBlue().toInt())
    return max(r, max(g, b)) / 255f
}

val Black = colorOf(0u, 0u, 0u, 255u)
val White = colorOf(255u, 255u, 255u, 255u)