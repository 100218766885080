package ch.ethz.icr.growup.pfe.router

import ch.ethz.icr.growup.pfe.error.ErrorPage
import ch.ethz.icr.growup.pfe.page.Page
import ch.ethz.icr.growup.pfe.view.*
import js.objects.jso
import js.promise.Promise.Companion.resolve
import mui.icons.material.*
import react.create
import react.router.dom.createBrowserRouter
import remix.run.router.LoaderFunction
import remix.run.router.LoaderFunctionArgs
import remix.run.router.Router

private val MATERIAL_VIEWS: Array<out View> = arrayOf(
    View("", "Intro", LandingView, HomeOutlined.create()),
    View("pfe", "View", MainView, StarsOutlined.create()),
    View("atlas", "Read", AtlasView, MenuBookOutlined.create()),
    View("rfe", "Download", DownloadView, DownloadOutlined.create()),
    View("about", "About", AboutView, FoundationOutlined.create()),
)

private val PageLoader: LoaderFunction<Any?> = {
    resolve(MATERIAL_VIEWS)
}.unsafeCast<LoaderFunction<Any?>>()

private val ViewMaterialLoader: LoaderFunction<Any?> = { args: LoaderFunctionArgs<Any?> ->
    println("ViewMaterialLoader $args")
    val id = args.params["viewId"]
    println("Resolving $id / ${args.params["countryId"]}")
    resolve(MATERIAL_VIEWS.single { it.key == id })
}.unsafeCast<LoaderFunction<Any?>>()

val Router: Router = createBrowserRouter(
    routes = arrayOf(
        jso {
            path = "/"
            loader = PageLoader
            Component = Page
            ErrorBoundary = ErrorPage
            children = arrayOf(
                jso {
                    index = true
                    Component = LandingView
                },
                jso {
                    path = ":viewId"
                    loader = ViewMaterialLoader
                    Component = ViewMaterial
                    ErrorBoundary = ErrorPage
                    children = arrayOf(
                        jso {
                            path = ":countryId"
                            loader = ViewMaterialLoader
                            Component = ViewMaterial
                            ErrorBoundary = ErrorPage
                        },
                        jso {
                            path = "*"
                            Component = ErrorPage
                        }
                    )
                },
                jso {
                    path = "*"
                    Component = ErrorPage
                }
            )
        },
    ),
)