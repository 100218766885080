package ch.ethz.icr.growup.pfe.theme

import js.objects.jso
import mui.material.PaletteMode
import mui.material.styles.TypographyOptions
import mui.material.styles.TypographyVariant
import mui.material.styles.createTheme
import web.cssom.atrule.maxWidth
import web.cssom.integer
import web.cssom.px
import web.cssom.rem

private val TYPOGRAPHY_OPTIONS = TypographyOptions {
    fontWeight = integer(500)

    TypographyVariant.h6 {
        fontSize = 1.5.rem

        media(maxWidth(599.px)) {
            fontSize = 1.25.rem
        }
    }
}

object Themes {
    val Light = createTheme(
        jso {
            palette = jso {
                mode = PaletteMode.light

                primary = jso {
                    // Header background color
//                    main = "#e65100"
                    main = "#1976d2" // Default
                }
                secondary = jso {
                    main = "#808080"
                }
                background = jso {
                    paper = "#ffffff"
                    // Content background color
                    default = "#ffffff"
//                    default = "#fafafa"
                }
            }
            typography = TYPOGRAPHY_OPTIONS
        }
    )

    val Dark = createTheme(
        jso {
            palette = jso {
                mode = PaletteMode.dark

                primary = jso {
//                    main = "#ffcc80"
                    main = "#90caf9" // Default
                }
                secondary = jso {
                    main = "#ffffff"
                }
                background = jso {
                    // General background color
                    paper = "#000000" //"#182f3c" // "#303030"
                    // Content background color
                    default = "#323232" // "#0e2232" For e
                }
            }
            typography = TYPOGRAPHY_OPTIONS
        }
    )
}