package ch.ethz.icr.growup.pfe

import ch.ethz.icr.growup.pfe.context.AppStateModule
import ch.ethz.icr.growup.pfe.router.Router
import ch.ethz.icr.growup.pfe.theme.ThemeModule
import ch.ethz.icr.growup.pfe.theme.YearModule
import react.FC
import react.Props
import react.router.RouterProvider

external interface PFEProps : Props {
}

val PFE = FC<PFEProps> { props ->
    ThemeModule {
        YearModule {
            AppStateModule {
                RouterProvider {

                    router = Router
                }
            }
        }
    }
}