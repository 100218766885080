package org.molap.index

import com.macrofocus.common.index.Index

interface UniqueIndex<K> : Index<K> {
    enum class Duplicate {
        /**
         * Keep the first item and remove all the duplicates
         */
        RemoveFirst,

        /**
         * Keep the last item and remove all the duplicates
         */
        RemoveLast,

        /**
         * Index first item but keep duplicates (lookup by address for duplicates will point to the first item)
         */
        UseFirst,

        /**
         * Index first item but keep duplicates (lookup by address for duplicates will point to the first item)
         */
        UseFirstWarn,

        /**
         * Index last item but keep duplicates (lookup by address for duplicates will point to the last duplicate)
         */
        UseLast,

        /**
         * Throw an exception
         */
        ThrowException
    }

    fun getAddress(key: K): Int
    fun head(count: Int): UniqueIndex<K>
    fun tail(count: Int): UniqueIndex<K>
//    fun union(other: UniqueIndex<K>): UniqueIndex<K>
//    fun intersection(other: UniqueIndex<K>): UniqueIndex<K>
//    fun difference(other: UniqueIndex<K>): UniqueIndex<K>
    fun keepAddresses(addresses: IntArray): UniqueIndex<K>
}