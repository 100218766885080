package com.macrofocus.common.collection

import kotlin.math.abs

class SortedSet<E>(
    val comparator: Comparator<E>,
    collection: Collection<E> = emptyList()
) : AbstractMutableSet<E>() {
    private val store = ArrayList<E>().apply {
        addAll(collection.toSet())
        sortWith(comparator)
    }

    override val size: Int
        get() = store.size

    override fun add(element: E): Boolean {
        val index = store.binarySearch(element, comparator)
        return if (index >= 0)
            false
        else {
            store.add(abs(index) - 1, element)
            true
        }
    }

    override fun iterator() = store.iterator()
}