package ch.ethz.icr.growup.pfe.page

import web.cssom.px

object Sizes {
    object Header {
        val Height = 64.px
    }

    object Footer {
        val Height = 24.px
    }

    object Sidebar {
        val Width = 135.px
    }
}